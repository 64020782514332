import { Box } from '@mui/material';
import React from 'react';
import { useLocation } from 'react-router-dom';
import NavBar, { NavBarProps } from '../NavBar';

interface HeaderProps {
  navBarProps: NavBarProps;
}

const Header: React.FC<HeaderProps> = ({ navBarProps }) => {
  const location = useLocation();

  const backgroundColor = location.pathname === '/about' ? '#F1E8DE' : '#FBF8F5';

  return (
    <header>
      <Box sx={{ backgroundColor }}>
        <NavBar {...navBarProps} />
      </Box>
    </header>
  );
};

export default Header;
