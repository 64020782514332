import { Box, Container, Stack, Typography, useTheme } from "@mui/material";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useGetPublicEstablishmentWithIdQuery } from "../../store/kcApi";
import { ResponsiveContainer } from "./landingPage";
import { useAuth0 } from "@auth0/auth0-react";
import KCButtonBase from "../../components/Button";
import { ReactComponent as RedFried } from '../../assets/svg/kc-fries-red.svg';

const NewReviewSubmittedPage: React.FC = () => {

  const { establishmentId } = useParams<{ establishmentId: string }>();
  const { reviewId } = useParams<{ reviewId: string }>();
  let navigate = useNavigate();
  const theme = useTheme();

  const { isAuthenticated, loginWithRedirect } = useAuth0();

  const {
    data: establishment,
  } = useGetPublicEstablishmentWithIdQuery({ establishmentId: establishmentId || '' });

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  if (isAuthenticated) {
    return (
      <ResponsiveContainer>
        <Container sx={{ paddingTop: "70px", paddingBottom: "70px" }}>
          <Stack
            spacing={4}
            justifyContent="center"
            alignItems="center"
          >
            <Box>
              <RedFried />
            </Box>
            <Typography variant='h2' color='text.secondary' align='center'>
              Thank You!
            </Typography>
            <Typography variant='body1' color='text.secondary' align='center'>
              Thank you for your review of {establishment?.name || "the establishment"}. Your experiences have the power to help others.
            </Typography>
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              useFlexGap flexWrap="wrap"
              spacing={2}
            >
              <KCButtonBase
                kcvariant="outlined"
                onClick={() => navigate(`${process.env.REACT_APP_OVERRIDE_PUBLIC_URL}/explore`)}
                sx={{ width: '315px' }}
              >
                Explore Page
              </KCButtonBase>
              <KCButtonBase
                variant='outlined'
                onClick={() => navigate(`${process.env.REACT_APP_OVERRIDE_PUBLIC_URL}/establishments/${establishmentId}`)}
                sx={{ width: '315px' }}
              >
                Back to {establishment?.name || "Establishment"}
              </KCButtonBase>
            </Stack>
          </Stack>
        </Container>
      </ResponsiveContainer>
    )
  }

  return (
    <ResponsiveContainer>
      <Container sx={{
        paddingTop: "70px",
        paddingBottom: "70px",
        [theme.breakpoints.down('md')]: {
          padding: "20px 0px",
        },
      }}>
        <Box
          sx={{
            backgroundColor: "#FFFFFF",
            borderRadius: "20px",
            padding: "90px 30px 160px 30px",
          }}
        >
          <Stack
            spacing={4}
            justifyContent="center"
            alignItems="center"
          >
            <Typography
              variant="h3"
              color="text.secondary"
              align='center'
              sx={{
                padding: "20px 0px 20px 0px",
                fontWeight: 'bold',
                [theme.breakpoints.down('md')]: {
                  fontSize: "24px",
                },
              }}
            >Submit review</Typography>
            <Typography
              variant='body1'
              color='text.secondary'
              align='center'
              sx={{
                padding: "0px 180px",
                [theme.breakpoints.down('md')]: {
                  fontSize: "16px",
                  padding: "0px",
                },
              }}
            >
              Create an account or log in to submit your review. This allows you to edit your review later if needed.<b>Your contact information will never be shared</b>.
            </Typography>
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <KCButtonBase
                variant='contained'
                onClick={() => loginWithRedirect({
                  appState: {
                    returnTo: `${process.env.REACT_APP_OVERRIDE_PUBLIC_URL}/establishments/${establishmentId}/new-review/${reviewId}/submitted`
                  },
                  authorizationParams: {
                    screen_hint: "signup"
                  }
                })}
                sx={{ width: '158px' }}
              >
                Sign up
              </KCButtonBase>
            </Stack>
          </Stack>
        </Box>
      </Container>
    </ResponsiveContainer>
  );
}

export default NewReviewSubmittedPage;