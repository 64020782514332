import { styled } from '@mui/material';
export default styled("div")({
    "@keyframes bg-animation": {
        "0%": { backgroundPosition: "0% 0%" },
        "10%": { backgroundPosition: "-5% -5%" },
        "20%": { backgroundPosition: "-10% 5%" },
        "30%": { backgroundPosition: "5% -10%" },
        "40%": { backgroundPosition: "-5% 15%" },
        "50%": { backgroundPosition: "-10% 5%" },
        "60%": { backgroundPosition: "15% 0%" },
        "70%": { backgroundPosition: "0% 10%" },
        "80%": { backgroundPosition: "-15% 0%" },
        "90%": { backgroundPosition: "10% 5%" },
        "100%": { backgroundPosition: "5% 0%" },
    },
    animation: "bg-animation .2s infinite",
    opacity: '.9',
    backgroundImage: 'url(./noise-transparent.png)',
    position: 'absolute',
    zIndex: -1,
    left: '0%',
    top: '0%',
    width: '100%',
    height: '100%',
    backgroundRepeat: 'repeat'
});