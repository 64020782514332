import { Box, Chip, Skeleton, Typography, styled } from '@mui/material';
import React from 'react';

import { ReactComponent as LightCheckmark } from '../../../../assets/svg/icons/light_checkmark.svg';


interface BenefitsProps {
  benefits?: {
    [key: string]: number;
  }
  isLoading?: boolean;
 
}

const Benefits: React.FC<BenefitsProps> = ({ benefits, isLoading }) => {
  const StyledBenefitsChip = styled(Chip)({
    backgroundColor: '#E44444',
    color: '#FFFFFF',
    borderRadius: '12px',
    padding: '12px 20px 12px 20px',
    margin: '5px',
    height: '40px',
  });

  if (!benefits) return null;

  return (
    <Box>
      <Typography 
        variant='subtitle2' 
        sx={{
          textTransform: 'uppercase',
          color: '#837A78',
          fontSize: '20px',
          padding: '0px 0px 20px 0px',
        }}
      >
        Benefits
      </Typography>
      {isLoading ? (
        <Box>
          <Skeleton variant="rounded" width={100} height={40} />
          <Skeleton variant="rounded" width={100} height={40} />
          <Skeleton variant="rounded" width={100} height={40} />
          <Skeleton variant="rounded" width={100} height={40} />
          <Skeleton variant="rounded" width={100} height={40} />
          <Skeleton variant="rounded" width={100} height={40} />
          <Skeleton variant="rounded" width={100} height={40} />
        </Box>
      ) : (
        Object.entries(benefits).map(([key, value]) => (
          <StyledBenefitsChip icon={<LightCheckmark />} key={key} label={key} />
        ))
      )}
    </Box>
  );
};

export default Benefits;
