import {
  Box, IconButton, useMediaQuery,
  useTheme
} from "@mui/material";
import React, { useEffect, useState, useMemo } from "react";
import { useDispatch } from "react-redux";
import { ReactComponent as ExitIcon } from '../../../../assets/svg/icons/exit_icon_dark.svg';
import { useAppSelector } from "../../../../hooks/hooks";
import { selectCurrentMapState, setMapState } from "../../../../store/mapSlice";
import EstablishmentFilterCard from "../EstablishmentFilterCard";

interface FilterCardProps {
  isLoading: boolean;
}

const FilterCardModal: React.FC<FilterCardProps> = ({
  isLoading,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();

  const { selectedMapMarkerEstId, visibleCardData }: any = useAppSelector(selectCurrentMapState);
  const data = useMemo(() => (visibleCardData || []), [visibleCardData]);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

  useEffect(() => {
    const newSelectedIndex = data.findIndex((item: any) => item.estId === selectedMapMarkerEstId);
    setSelectedIndex(newSelectedIndex !== -1 ? newSelectedIndex : null);
  }, [data, selectedMapMarkerEstId]);

  const [openModal, setOpenModal] = useState(false);

  const handleCloseModal = () => {
    setOpenModal(false);
    dispatch(setMapState({ selectedMapMarkerEstId: null }));
  }

  useEffect(() => {
    if (isMobile && selectedIndex !== null) setOpenModal(true);
  }, [selectedIndex, isMobile]);
  return (
    <>
      {openModal && selectedIndex !== null && selectedIndex < data.length &&
        <Box
          bgcolor='white'
          position="fixed"
          left={16}
          right={16}
          bottom={10}
          borderRadius={4}
          zIndex={10}
          sx={{
            filter: 'drop-shadow(0px 1px 12px rgba(0, 0, 0, 0.25))'
          }}
        >
          <IconButton sx={{ position: 'absolute', right: '10px', top: '10px' }} onClick={handleCloseModal}>
            <ExitIcon width={16} height={16} />
          </IconButton>
          <EstablishmentFilterCard
            key={data[selectedIndex].estId}
            name={data[selectedIndex].name}
            address={data[selectedIndex].address}
            baseRate={data[selectedIndex].baseRate}
            avgTips={data[selectedIndex].avgTips}
            distance={data[selectedIndex].distance}
            sanitationScore={data[selectedIndex].sanitationScore}
            image={data[selectedIndex].image}
            starRating={data[selectedIndex].starRating}
            numOfReviews={data[selectedIndex].numOfReviews}
            estId={data[selectedIndex].estId}
            isHiring={data[selectedIndex].isHiring}
          />
        </Box>
      }
    </>
  );
};

export default FilterCardModal;
