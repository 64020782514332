import { Drawer, DrawerProps, styled } from "@mui/material";

const StyledDrawer = styled(Drawer)<DrawerProps>(({ theme }) => ({
  '& .MuiDrawer-paper': {
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    minHeight: '10vh',
  },
}));

const PositionDrawer: React.FC<DrawerProps> = (props) => {
  return (
    <StyledDrawer {...props}>
      {props.children}
    </StyledDrawer>
  );
}

export default PositionDrawer;