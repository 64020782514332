import React from "react";
import { Container, Typography } from "@mui/material";

const PrivacyPolicyPage: React.FC = () => {
  return (
    <Container>
      <Typography variant="h2">Privacy Policy</Typography>
      <p><strong>Kitchen Confidentials Inc.</strong></p>
        <p><strong>PRIVACY POLICY</strong></p>
        <p>Last revised: [October 14, 2022]</p>
        <p>At [Kitchen Confidentials Inc] (&rdquo;[KC]&rdquo;,&rdquo;[Heard.]&rdquo;,&rdquo;[Heard. NYC]&rdquo;) your privacy is important to us. Our Privacy Policy describes the information we collect, how we collect information, and the reasons we collect information. This Privacy Policy also describes the choices you have regarding the information [KC] collects, including how you can manage, update, or request to delete information.</p>
        <p>Please take a moment to review this Privacy Policy. You may scroll through this Privacy Policy or use the headings below. It is important that you understand this Privacy Policy. By using our Platform, you are agreeing to the terms of this Privacy Policy. If you have any questions or concerns about this Privacy Policy, you may Contact Us at any time.</p>
        <p><strong>CONTENTS</strong></p>
        <ol>
        <li>Key Terms &amp; Definitions and Our Privacy Policy</li>
        <li>Personal Information</li>
        <li>Children's Privacy</li>
        <li>Data Security</li>
        <li>Supplemental Disclosures and Rights Based on State Law</li>
        <li>International Users</li>
        <li>Changes to our Privacy Policy</li>
        <li>Contact Us</li>
        <li>Glossary</li>
        </ol>
        <ol>
        <li><span><strong>Key Terms &amp; Definitions and Our Privacy Policy</strong></span></li>
        </ol>
        <p>It is helpful to start by explaining some of our key terms and definitions used in this Privacy Policy.</p>
        <p>&rdquo;<strong>App</strong>&rdquo;: Our mobile applications</p>
        <p>&rdquo;<strong>Personal Information</strong>&rdquo; or <strong>Personal Data</strong>&rdquo;: Information identifying, relating to or about an identified or identifiable individual, as described more fully in this Policy.</p>
        <p>&rdquo;<strong>Platform</strong>&rdquo;: Our Website(s), App(s), and related functionality and online services, as applicable.</p>
        <p>&rdquo;<strong>Privacy Policy</strong>&rdquo; or <strong>Policy</strong>&rdquo;: This privacy policy.</p>
        <p>&rdquo;<strong>Website</strong>&rdquo;: Our website located at heardnyc.com</p>
        <p>&rdquo;<strong>Services</strong>&rdquo;: Any services provided through our Platform, which maybe for purchase at a charge, or included at no charge as part of our Platform, or that we otherwise provide or sell to you.</p>
        <p>&rdquo;<strong>[KC]</strong>&rdquo;, &rdquo;<strong>[Heard.]</strong>&rdquo;, &rdquo;<strong>[Heard. NYC]</strong>&rdquo;, &rdquo;<strong>we</strong>,&rdquo; &rdquo;<strong>us</strong>&rdquo;: [Kitchen Confidentials Inc].</p>
        <p>Please refer to our <a href="https://docs.google.com/document/d/1HIsNliaPSIBtmj9PtVfKzPz85t6R6rz7Bk-VJ8o-Q54/edit#bookmark=kix.1h4tklbfzarb" target="_blank" rel="noreferrer"><span><strong>Glossary</strong></span></a> for additional explanations of terms and phrases used in this Policy.</p>
        <p><strong>When does our Privacy Policy apply?</strong></p>
        <p>This Privacy Policy describes the types of information we may collect from you when you visit or use our Platform or any components of our Platform, and when we communicate with you electronically, such as through our Platform, email, text message and other electronic messages between [Client Short] and you.</p>
        <p><strong>When does our Privacy Policy not apply?</strong></p>
        <p>This Privacy Policy does not apply to information (a) we collect from you through any in-person or face-to-face interactions we have with you, or through an audio-only telephone conversations; (b) by any other websites or platforms operated by us, unless the website or platform is listed above or links to this Privacy Policy; (c) collected by any third party website that we may provide a link to or that is accessible from our Platform; or (d) covered in part or in whole by a separate privacy policy provided by us (e.g., relating specifically to health information, financial information, other special information, etc.).</p>
        <p><strong>Terms of Use.</strong></p>
        <p>This Privacy Policy is incorporated into and governed by our <a href="https://docs.google.com/document/d/11Xw6OYa1L75iXuA98xeRp_n25FIKJhDEGi9Hs3HYbsM/edit" target="_blank" rel="noreferrer">Terms of Use</a>, which also apply when you use our Platform.</p>
        <ol>
        <li><span><strong>Personal Information</strong></span></li>
        </ol>
        <p><strong>What is Personal Information?</strong></p>
        <p>Personal Information is information that you provide to us which personally identifies you, such as your name, email address, or billing information, work address, phone number, IP address, Date of Birth, credit and debit card numbers, or other data that can be reasonably linked to such information by [Client Short], such as information we associate with your [Client Short] account.</p>
        <p><strong>What types of Personal Information do we collect?</strong></p>
        <p>We collect and use Personal Information in order to operate and provide our Platform to you. You may provide Personal Information to us, and we may collect Personal Information from you automatically as you use and navigate through our Platform.</p>
        <p><strong>How do we collect your Personal Information?</strong></p>
        <p><strong><em>Information you provide to us.</em></strong> You may provide Personal Information to us through our Platform. For example, you may be able to register to use our Platform or create an account with us, which allows or requires you to provide certain information to us (e.g., contact information such as your name, email address, telephone number, etc.). You may also be able to make payments to us, through our Platform. In these situations, we collect your credit card, debit card or other payment card information or bank or other financial account information. You provide Personal Information and other information to when you contact us through our Platform. You may also be able to add, post or upload content or other materials to our Platform, such as ___________.</p>
        <p><strong><em>Information we collect as you use our Platform.</em></strong> We collect Personal Information and information about the computers, devices, browsers and your Internet activity as you use and interact with our Platform. The information we collect includes Unique Identifier, browser type and settings, device type and settings, operating system, mobile network information including carrier name and phone number, and application version number. We also collect information about the interaction of your apps, browsers, and devices with our services, including IP address, device type, crash reports, system activity, wireless carrier name (when you use a wireless or mobile device), and the date, time, and referrer URL of your request.</p>
        <p><strong><em>Online Activity.</em></strong> We collect information about your activity on our Platform, Internet, network, and other online activity information, such as browsing history, search history, and information regarding your interaction with our Platform and other websites. We use various technologies to collect and store location information, including cookies, pixels or pixel tags, local storage, such as browser web storage or application data caches, databases, session replay, and server logs.</p>
        <p><strong><em>Location.</em></strong> We collect information about your location when you use our Platform, which helps us provide our Platform Services, features and functionality. Your location can be determined with varying degrees of accuracy by:</p>
        <ul>
        <li>GPS</li>
        <li>IP address</li>
        <li>Sensor data from your device</li>
        <li>Information about things near your device, such as Wi-Fi access points, cell towers, and Bluetooth-enabled devices</li>
        </ul>
        <p>The types of location data we collect depend in part on your device and account settings. For example, you can turn your mobile device's location on or off using the device's settings app. You may also be able to turn on location history if you want to create a private map of where you go with your devices.</p>
        <p><strong><em>Third Party Sources</em></strong>. In some circumstances, [Client Short] also collects information about you from publicly accessible sources. We may collect information about you from trusted partners, such as marketing partners who provide us with information about our potential customers, and security partners who provide us with information to protect against fraud and abuse of our Platform.</p>
        <p><strong>Why and how do we use your Personal Information?</strong></p>
        <p><strong><em>Provide our Platform and Related Services</em></strong>. We use your Personal Information to operate, maintain, supervise, administer, improve and enhance our Platform and related Services, features and functionality. We use your contact information to communicate and interact with you, such as to send you emails and text messages about our Platform. We use the IP address assigned to your device to send you the data you request to display on your device. We use unique identifiers stored in cookies on your device to help us authenticate you as the person who should have access to certain areas and features of our Platform. We also use your information to ensure our Platform is working as intended, such as tracking outages or troubleshooting issues that you report to us. And we use your information for research and development for our business, and to make improvements to our Platform.</p>
        <p>We use data collected from you and your devices for analytics and measurement to understand how our Platform is used. For example, we analyze data about your use of our Platform to do things like optimize product design. We use a variety of tools to do this, such as Google Analytics and similar third party online data analytics services such as Google Ads, Facebook Pixel, Instagram, Twitter, and Stripe.</p>
        <p><strong><em>Security and Legal Purposes.</em></strong> We use your information to help improve the safety and reliability of our Platform. This includes detecting, preventing, and responding to fraud, abuse, security risks, and technical issues that could harm [Client Short], our users, or the public. Information may also be used in connection for legal reasons and purposes, such as to comply with applicable law, regulation, legal process, or enforceable governmental request; to enforce our Terms of Use , including investigation of potential violations; and to detect, prevent, or otherwise address fraud, security, or technical issues; and to protect against harm to the rights, property or safety of [Client Short], our users, or the public as required or permitted by law.</p>
        <p>We may also use Personal Information to:</p>
        <ul>
        <li>Carry out our legal and contractual obligations, and enforce our rights arising from any contracts entered into between you and us, including for billing and collection.</li>
        <li>To respond to law enforcement requests, court orders, and subpoenas and to carry out our legal and contractual obligations.</li>
        <li>Authenticate use, detect fraudulent use, and otherwise maintain the security of our Platform and the safety of others.</li>
        <li>To administer surveys and questionnaires.</li>
        <li>To maintain, customize, and secure the individual's account (including notices about subscriptions, expiration, and renewals, if applicable).</li>
        <li>To process the individual's requests, purchases, transactions, and payments and/or to prevent transactional fraud.</li>
        <li>To allow the user to participate in the interactive features of your website/service.</li>
        <li>To provide the individual with support and to respond to his/her inquiries (including to investigate and address concerns and monitor/improve responses).</li>
        <li>Any other advertising or marketing of your own or your affiliates' products and services.</li>
        <li>To help maintain the safety, security, and/or integrity of your business, website, products, services, databases, and other technology assets.</li>
        <li>For internal testing, research, analysis, and product development, including to develop and improve your website/application, and to develop, improve, or demonstrate your products/services.</li>
        <li>Any other purpose with your consent.</li>
        </ul>
        <p><strong><em>Personalized and interest-based advertising.</em></strong>  We partner with third party advertising networks that collect IP addresses, unique device identifiers, browser type, operating system, time zone, country, referring pages, and other information through the use of cookies, pixel tags, and server logs on our Platform. They use this information to provide you with interest-based advertisements that are customized to your particular preferences, including for companies that are not affiliated with us. You may see these interest-based advertisements on our Platform, as well as on third party websites and apps, and across different devices you use. We may use this process to help us manage and improve the effectiveness of our marketing efforts. It also allows to display ads to our users about [Client Short] after they leave our Platform, and to track users after they see or click on an advertisement, keep track of users who access our Platform or advertisements from different devices, and better provide advertisements to our target audiences. The data collected through these tracking technologies is also saved and processed by our advertising and marketing service provider partners.</p>
        <p><strong>Why and how do we share your Personal Information?</strong></p>
        <p>We share Personal Information with third parties under certain circumstances and for certain purposes described throughout this Policy, including:</p>
        <ul>
        <li><strong>Service providers and other to operate our Platform and Services</strong>. We share your Personal Information with our affiliates, vendors, service providers, and business partners, including providers and vendors we use for operating and maintaining our Platform, and its features, functionality and Services. These third parties include data hosting and data storage partners, analytics, ad network, advertising including interest-based advertising, technology services and support, and data security.</li>
        <li><strong>Our business purposes</strong>. We may share your Personal Information with our affiliates, vendors, service providers, and business partners, including providers and vendors we use for our business activities and operations generally, such as data hosting and data storage partners, analytics, ad network, advertising, technology services and support, and data security. We may also share your Personal Information with professional advisors, such as auditors, law firms, and accounting firms.</li>
        <li><strong>With your consent</strong>. We may share your Personal Information if you request or direct us to do so.</li>
        <li><strong>Compliance with law</strong>. We may share your Personal Information to comply with applicable law or any obligations thereunder, including cooperation with law enforcement, judicial orders, and regulatory inquiries.</li>
        <li><strong>Business transfers</strong>. We may share your Personal Information to a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of our assets, whether as a going concern or as part of a bankruptcy, liquidation, or similar proceeding, in which Personal Information held by us about our users are among the assets transferred.</li>
        <li><strong>To enforce our rights</strong>. We may share your Personal Information to enforce our Terms of Use and any other agreement, terms and conditions relating to your use of the Platform. We also may share information as needed to ensure the safety and security of our Platform and our users, and to detect, prevent, or otherwise address fraud, security, or technical issues</li>
        <li><strong>De-identified information</strong>. We may also de-identified information, so that it cannot be reasonably used to identify any individual, with third parties for marketing, advertising, research, or similar purposes.</li>
        </ul>
        <p><strong>Your choices for how we collect, use and share your Personal Information.</strong></p>
        <p>We offer you choices on how you can opt out of our certain uses and sharing of your Personal Information. As a general rule, you cannot opt out of our collection, use and sharing of Personal Information to the extent it is necessary to provide the Platform or related Services, features and functionality to you.</p>
        <p>You can change the cookie settings that will be placed when you use our Platform by changing the settings on your Internet browser. You can also block cookies by activating the setting on your browser that allows you to refuse the setting of all or some cookies. Please note that internet browsers allow you to change your cookie settings. These settings are usually found in the 'options' or 'preferences' menu of your internet browser. However, if you use your browser settings to block all cookies (including strictly necessary cookies), you may not be able to access or use all or areas and aspects of our Platform.</p>
        <p>You can generally opt out of receiving interest-based advertisements from third party advertisers and ad networks who are members of the Network Advertising Initiative (NAI) or who follow the Digital Advertising Alliance's Self-Regulatory Principles for Online Behavioral Advertising by visiting the opt out pages on the NAI website and DAA website. We do not control third parties' collection or use of your information to serve interest-based advertising. However, these third parties may provide you with ways to choose not to have your information collected or used in this way. You can also opt out of receiving targeted ads from members of the NAI on its website. You can learn more about interest-based advertisements and your opt out rights and options on the NAI website and DAA website.</p>
        <p>If you do not wish to have your email address used by [Client Short] to send you advertising messages and content, you can opt out at any time by clicking the unsubscribe link at the bottom of any marketing emails you receive from us. You may have other options with respect to marketing and communication preferences through our Platform.</p>
        <p><strong>How do I access and correct my Personal Information?</strong></p>
        <p><strong><em>Access, Corrections and Deletion</em></strong>. In addition to any data access capabilities available through the Platform, please Contact Us if you have any questions about your Personal Information. Please inform us of any changes or errors in any Personal Information we have about you to ensure that it is complete, accurate, and as current as possible. You may also have certain deletion rights in accordance with applicable law. We may not be able to accommodate your request if we believe it would violate any law or legal requirement or cause the information to be incorrect.</p>
        <p><strong><em>Copies and Retention of Data</em></strong>. In addition to any capabilities available through the Platform, if you need to export or a copy of your data, please let us know and we will assist you with your request. We retain the data we collect for different periods of time depending on what it is, how we use it and applicable legal requirements. We may retain some data for longer periods of time than other data when necessary for legitimate business or legal purposes, such as security, fraud and abuse prevention, or financial record-keeping.</p>
        <ol>
        <li><span><strong>Children's Privacy</strong></span></li>
        </ol>
        <p>Our Platform is not intended for children under 13 years of age. We do not knowingly collect or sell Personal Information from children under the age of 13. If you are under the age of 13, do not use or provide any information on or to the Platform or through any of its features. If we learn we have collected or received Personal Information from a child under the age of 13 without verification of parental consent, we will delete it. If you are the parent or guardian of a child under 13 years of age whom you believe might have provided use with their Personal Information, you may Contact Us to request the Personal Information be deleted.</p>
        <ol>
        <li><span><strong>Data Security</strong></span></li>
        </ol>
        <p>We have taken steps and implemented administrative, technical, and physical safeguards designed to protect against the risk of accidental, intentional, unlawful, or unauthorized access, alteration, destruction, disclosure, or use. The Internet is not 100% secure and we cannot guarantee the security of information transmitted through the Internet. Where you have been given or you have chosen a password, it is your responsibility to keep this password confidential.</p>
        <p>The sharing and disclosing of information via the internet is not completely secure. We strive to use best practices and industry standard security measures and tools to protect your data. However, we cannot guarantee the security of Personal Information transmitted to, on, or through our Services. Any transmission of Personal Information is at your own risk. We are not responsible for the circumvention of any privacy settings or security measures contained on our Platform, in your operating system, or mobile device.</p>
        <ol>
        <li><span><strong>Supplemental Disclosures and Rights Based on State Law</strong></span></li>
        </ol>
        <p>In addition to the disclosures and rights set forth elsewhere in this Policy, you and other users may have certain rights based on applicable state law (e.g., California's Consumer Privacy Act (CCPA), as amended by the California Privacy Rights Act). Other states have passed consumer privacy laws that may be applicable to [Client Short] and your use of this Site. These supplemental disclosures and rights apply to you and your use of our Platform to the extent applicable state law applies to [Client Short] and your use of our Platform. Please contact us if you have any questions regarding your rights under applicable state law.</p>
        <p><strong>Categories of Personal Information Collected</strong></p>
        <p>As described in more detail in other areas of our Privacy Policy, we collect and/or disclose Personal Information about you when you visit use our Digital Services, including information about you that you provide to us, and information that we automatically collect from you or your computer or device as you use our Digital Services.</p>
        <p>Personal information does not include information that is: (a) publicly available information from government records; (b) de-identified or aggregated consumer information; or (c) certain information excluded from the scope of applicable state law (e.g., PHI covered under HIPAA and medical information may be covered under HIPAA and other state laws).</p>
        <p><strong>Categories of Sources From Which We Have Collected Personal Information</strong></p>
        <p>We collect Personal Information directly from you, for example when you provide it to us, when you contact us through our Digital Services, when you create a [Client Short] account; and indirectly from you automatically through your computer or device as you use our Digital Services. We may also collect Personal Information about you from our advertising partners and service providers.</p>
        <p><strong>Use of Personal Information</strong></p>
        <p>We do not sell your Personal Information and have not done so in the prior 12 months from the effective date of this Policy. We may use or disclose the personal information we collect for our business purposes described elsewhere in this Privacy Policy.</p>
        <p><strong>Sharing Personal Information</strong></p>
        <p>[Client Short] may disclose your Personal Information to a third party for one or more business purposes. When we disclose Personal Information for a business purpose, such as to service providers, we enter a contract that describes the purpose and requires the recipient to both keep that Personal Information confidential and not use it for any purpose except performing the contract.</p>
        <p><strong>Disclosures of Personal Information for Business Purposes</strong></p>
        <p>We may disclose your Personal Information for our business purposes, such as your contact information, other information you have provided to us and unique identifiers that identify you to us or to our service providers, such as companies that assist us with marketing and advertising. We disclose your Personal Information to certain third parties such as our vendors, business partners, service providers, including companies that assist us with marketing and advertising.</p>
        <p><strong>Access Request Rights</strong></p>
        <p>You may have the right to request that [Client Short] disclose certain information to you about our collection and use of your Personal Information over the past 12 months for the above business and commercial purposes. To submit an access request, see Exercising Access and Deletion Rights, below. Once we receive and confirm your verifiable consumer request, we will disclose to you:</p>
        <ul>
        <li>The categories of Personal Information we collected about you.</li>
        <li>The categories of sources for the Personal Information we collected about you.</li>
        <li>Our business or commercial purpose for collecting that Personal Information.</li>
        <li>The categories of third parties with whom we share that Personal Information.</li>
        <li>The specific pieces of Personal Information we collected about you.</li>
        <li>If we sold or disclosed your Personal Information for a business purpose, two separate lists disclosing:</li>
        <ul>
        <li>Sales, identifying the Personal Information categories that each category of recipient purchased; and</li>
        <li>Disclosures for a business purpose, identifying the Personal Information categories that each category of recipient obtained.</li>
        </ul>
        </ul>
        <p><strong>Deletion Request Rights</strong></p>
        <p>You may have the right to request that [Client Short] delete your Personal Information that we collected from you and retained, subject to certain exceptions. Once we receive and confirm your verifiable consumer request, we will delete (and direct our service providers to delete) your Personal Information from our records, unless certain exceptions apply.</p>
        <p><strong>Exercising Access and Deletion Rights</strong></p>
        <p>To exercise the access and deletion rights described above, please submit a verifiable consumer request to us by either:</p>
        <ul>
        <li>Calling us at <span>(718) 218-5758‬</span></li>
        <li>Emailing us at Info@kitchenconfidentials.com</li>
        </ul>
        <p>Only you or your authorized and legal representative authorized to act on your behalf, may make a verifiable consumer request related to your Personal Information. You may also make a verifiable consumer request on behalf of your minor child. You may only make a verifiable consumer request for access twice within a 12-month period. The verifiable consumer request must:</p>
        <ul>
        <li>Provide sufficient information that allows us to reasonably verify you are the person about whom we collected Personal Information or an authorized representative.</li>
        <li>Describe your request with sufficient detail that allows us to properly understand, evaluate, and respond to it.</li>
        </ul>
        <p><strong>Non-Discrimination</strong></p>
        <p>We will not discriminate against you for exercising any of your rights under applicable state law. Unless permitted by applicable law, we will not:</p>
        <ul>
        <li>Deny you goods or services.</li>
        <li>Charge you different prices or rates for goods or services, including through granting discounts or other benefits, or imposing penalties.</li>
        <li>Provide you a different level or quality of goods or services.</li>
        <li>Suggest that you may receive a different price or rate for goods or services or a different level or quality of goods or services.</li>
        </ul>
        <ol>
        <li><span><strong>International Users</strong></span></li>
        </ol>
        <p>Our Platform is not intended for use by and is not directed to residents of the European Union. All data is stored and processed in the United States. By using and accessing our site, users who reside or are located in countries outside of the United States agree and consent to the transfer to and processing of personal information on servers located outside of the country where they reside, and that the protection of such information may be different than required under the laws of their residence or location.</p>
        <ol>
        <li><span><strong>Changes to our Privacy Policy</strong></span></li>
        </ol>
        <p>We may update our Privacy Policy periodically to reflect changes in our privacy practices, laws, and best practices. If we make any changes to our Privacy Policy, we will notify you by email to the email address specified in your account. If we make material changes to our practices with regards to the Personal Information we collect from you, we will notify you by email to the email address specified in your account. The date this Privacy Policy was last revised is identified at the top of the page. You are responsible for ensuring we have an up-to-date active and deliverable email address for you, and for periodically accessing our Platform and reviewing this Privacy Policy to check for any changes.</p>
        <ol>
        <li><span><strong>Contact Us</strong></span></li>
        </ol>
        <p>If you have any questions, concerns, complaints or suggestions regarding our Privacy Policy or otherwise need to contact us, you may contact us at the contact information below or through the <a href="https://kitchenconfidentials.com/contactUs" target="_blank" rel="noreferrer">Contact Us</a> page on our Platform.</p>
        <p>[Kitchen Confidentials Inc]</p>
        <p>[8 the grn</p>
        <p>STE 13908</p>
        <p>Dover, DE</p>
        <p>19901]</p>
        <p>Telephone: [<span>718 218-5758‬</span>]</p>
        <p>Email: [Info@kitchenconfidentials.com]</p>
        <ol>
        <li><span><strong>Glossary</strong></span></li>
        </ol>
        <p><strong>Browser Web Storage</strong> enables websites to store data in a browser on a device. When used in "local storage" mode, it enables data to be stored across sessions. This makes data retrievable even after a browser has been closed and reopened. One technology that facilitates web storage is HTML 5.</p>
        <p>A <strong>Cookie</strong> is a small file containing a string of characters that is sent to your computer when you visit a website. When you visit the site again, the cookie allows that site to recognize your browser. Cookies may store user preferences and other information. You can configure your browser to refuse all cookies or to indicate when a cookie is being sent. However, some website features or services may not function properly without cookies.</p>
        <p>A <strong>Pixel or Pixel Tag</strong> is a type of technology placed on a website or within the body of an email for the purpose of tracking certain activity, such as views of a website or when an email is opened. Pixel tags are often used in combination with cookies.</p>
        <p>An <strong>Application Data Cache</strong> is a data repository on a device. It can, for example, enable a web application to run without an internet connection and improve the performance of the application by enabling faster loading of content.</p>
        <p><strong>Interest-based Advertising</strong> is sometimes referred to as personalized or targeted ads. Interest-based ads are used to display features, products, and services that might be of interest to the user.</p>
        <p>To serve interest-based ads, we may use information such as your interactions with our Platform, Services and related content. We do not use information which on its own identifies you, such as name or e-mail address, to serve interest-based ads. As is common in the advertising industry, we use cookies, pixels, and other technologies which enable us to understand the effectiveness of the interest-based ads we show you by measuring what ads are clicked or viewed, and to provide you with more useful and relevant ads. For example, if we know what ads are shown to your browser we can be careful not to show the same ads repeatedly. We work with third parties, such as advertisers, publishers, social media networks, search engines, ad serving companies, and advertising companies working on their behalf, to improve the relevance of ads we serve.</p>
        <p>Advertisers and other third parties may assume or infer that users who interact with or click on an interest-based ad or content are part of the group that the ad or content is directed towards (for example, users in a particular geographical area or users who purchased or browsed for classical music). Third party advertisers or advertising companies working on their behalf sometimes use cookies in the process of delivering content, including ads, directly to your browser or device, and they may automatically receive an IP address when this happens. They may also use cookies to measure the effectiveness of their ads, show you more relevant advertising content, and perform services on behalf of [Client Short].</p>
        <p><strong>Server Logs</strong>. Like most websites, our servers automatically record the page requests made when you visit our sites. These server logs&rdquo; typically include your web request, Internet Protocol address, browser type, browser language, the date and time of your request, and one or more cookies that may uniquely identify your browser.</p>
        <p><strong>Session Replay</strong> provides the ability to replay a visitor's journey on a web site or within a mobile application or web application. Replay can include the user's view (browser or screen output), user input (keyboard and mouse inputs), and logs of network events or console logs. Session replay is used to help improve customer experience, analyze usability and help identify obstacles in conversion processes on websites. It can also be used to study a website's usability, customer behavior, interests, and the handling of customer service questions as the customer journey, with all interactions, can be replayed. It can also be used to analyze fraudulent behavior on websites.</p>
        <p>A <strong>Unique Identifier</strong> is a string of letters, numbers and characters that can be used to uniquely identify a computer, device, personal device, browser or app.</p>
        <p>Different identifiers vary in how permanent they are, whether they can be reset by users, and how they can be accessed. Unique Identifiers can be used for various purposes, including security and fraud detection, syncing data from your device(s) to our Platform, remembering your preferences, and providing personalized advertising. You can configure your browser to refuse all cookies or to indicate when a cookie is being sent. See your browser documentation for additional information.</p>
        <p>On other platforms besides browsers (e.g., personal devices), Unique Identifiers are used to recognize a specific device or app on that device. For example, a Unique Identifier can be used to provide relevant advertising on mobile devices, and can be managed in your device's settings. Unique identifiers may also be incorporated into a device by its manufacturer (sometimes called a universally unique ID or UUID), such as the IMEI-number of a mobile phone. For example, a device's unique identifier can be used to customize our Platform and Services to your device or analyze device issues related to our Platform and Services.</p>
      
    </Container>
  );
};

export default PrivacyPolicyPage;
