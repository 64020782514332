import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query'
import { kcApi } from './kcApi'
import counterReducer from '../features/counter/counterSlice';
import authSlice from './authSlice';
import mapSlice from './mapSlice';
import filterSlice from './filterSlice';
import savedJobsSlice from './savedJobsSlice';

export const store = configureStore({
  reducer: {
    [kcApi.reducerPath]: kcApi.reducer,
    counter: counterReducer,
    auth: authSlice,
    mapState: mapSlice,
    filterState: filterSlice,
    savedJobs: savedJobsSlice,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(kcApi.middleware),
});

setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
