import CircleIcon from '@mui/icons-material/Circle';
import { Alert, AlertColor, Box, Container, Divider, Grid, Link, Stack, Typography, styled, useTheme } from '@mui/material';
import { Form, Formik, FormikHelpers } from 'formik';
import React from 'react';
import ReactGA from 'react-ga4';
import * as yup from 'yup';
import { ReactComponent as Facebook } from '../../assets/svg/Socials/facebook.svg';
import { ReactComponent as Instagram } from '../../assets/svg/Socials/instagram.svg';
import { ReactComponent as Tiktok } from '../../assets/svg/Socials/tiktok.svg';
import { ReactComponent as Twitter } from '../../assets/svg/Socials/twitter.svg';
import { CreateSubscriptionApiArg, useCreateSubscriptionMutation } from '../../store/kcApi';
import KCSearchField from '../KCSearchField';

const Footer: React.FC = () => {

  const theme = useTheme();

  const StyledLink = styled(Link)(({ theme }) => ({
    color: theme.palette.common.white,
    '&:hover': {
      color: theme.palette.primary.main,
    },
    textDecoration: 'none',
  }));

  interface NavLink {
    name: string;
    link: string;
  }

  const navLinks: NavLink[] = [
    {
      name: 'Home',
      link: '/home',
    },
    {
      name: 'Explore',
      link: '/explore',
    },
    {
      name: 'About',
      link: '/about',
    },
    {
      name: 'Contact',
      link: '/contact',
    },
    {
      name: 'Blog',
      link: 'https://blog.heardnyc.com/',
    },
    {
      name: 'Login',
      link: `${process.env.REACT_APP_OVERRIDE_PUBLIC_URL}/login`,
    }
  ];

  interface alertValues {
    type: AlertColor,
    message: string,
  }


  const [subscribeUser] = useCreateSubscriptionMutation();
  const [alert, setAlert] = React.useState<alertValues>({} as alertValues);
  const [alertOpen, setAlertOpen] = React.useState(false);

  const subscribeAction = (props: FormikHelpers<any>) => {
    ReactGA.event({
      category: "User Submit Interaction",
      action: "Click",
      label: "Subscribe"
    })
    props.submitForm();
  }

  const submitSubscribe = (values: any, formikHelpers: any) => {
    const subReq: CreateSubscriptionApiArg = {
      subscription: {
        email: values.email,
        subject: "web-footer-subscription",
      }
    }

    subscribeUser(subReq).unwrap()
      .then((response) => {
        formikHelpers.resetForm();
        setAlert({ type: "success", message: "Subscribed successfully!" });
        setAlertOpen(true);
        ReactGA.event({
          category: "Form Result",
          action: "Submit",
          label: "Subscribe Success"
        })
      })
      .catch((error) => {
        setAlert({ type: "error", message: "An error occurred while subscribing!" });
        setAlertOpen(true);
        ReactGA.event({
          category: "Form Result",
          action: "Submit",
          label: "Subscribe Error"
        })
      })
  }

  return (
    <footer>
      <Box sx={{
        backgroundColor: "secondary.main",
        padding: "80px 0px 80px 0px",
        [theme.breakpoints.down('md')]: {
          padding: "20px 0px",
        },
      }}>
        <Container fixed>
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            divider={<Divider orientation="vertical" variant='middle' flexItem sx={{ borderColor: "#FBF8F5" }} />}
            spacing={{ xs: 4, md: 12 }}
            justifyContent='center'
            alignItems='center'
          >
            <Box>
              <Stack spacing={2}>
                <Typography
                  variant="h4"
                  color="white"
                  sx={{
                    [theme.breakpoints.down('md')]: {
                      textAlign: 'center',
                    },
                  }}
                >
                  Stay up to date
                </Typography>
                <Typography
                  variant="body1"
                  color="white"
                  sx={{
                    [theme.breakpoints.down('md')]: {
                      textAlign: 'center',
                    },
                  }}
                >
                  Subscribe for blog posts and other updates.
                </Typography>
                <Formik
                  initialValues={{ email: '' }}
                  validationSchema={yup.object({
                    email: yup.string().email('Invalid email address').required('Required'),
                  })}
                  onSubmit={submitSubscribe}
                >
                  {formik => (
                    <Form>
                      {alertOpen && <Alert severity={alert.type} onClose={() => setAlertOpen(false)}>{alert.message}</Alert>}
                      <KCSearchField
                        sx={{ paddingTop: "20px" }}
                        id='email'
                        name='email'
                        outlined={false}
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.email && Boolean(formik.errors.email)}
                        helperText={formik.touched.email && formik.errors.email}
                        buttontext='Subscribe'
                        placeholder='Enter your email...'
                        buttonHandleClick={() => subscribeAction(formik)}
                      />
                    </Form>
                  )}

                </Formik>

              </Stack>
            </Box>
            <Box>
              <Grid container spacing={12}>
                <Grid item xs={6}>
                  <Stack spacing={2}>
                    {navLinks.slice(0, 4).map((link) => (
                      <StyledLink variant="body2" href={link.link} key={link.name}>
                        {link.name}
                      </StyledLink>
                    ))}
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Stack spacing={2}>
                    {navLinks.slice(4, 6).map((link) => (
                      <StyledLink variant="body2" href={link.link} key={link.name}>
                        {link.name}
                      </StyledLink>
                    ))}
                  </Stack>
                </Grid>
              </Grid>
              <Stack direction='row' spacing={4} sx={{ paddingTop: '40px' }}>
                <Link href="https://www.reddit.com/r/KitchenConfidentials/" sx={{ display: 'flex', alignItems: 'center', color: 'white' }} target="_blank" rel="noopener">
                  <Twitter />
                </Link>
                <Link href="https://www.instagram.com/kitchen.confidentials" sx={{ display: 'flex', alignItems: 'center', color: 'white' }} target="_blank" rel="noopener">
                  <Instagram />
                </Link>
                <Link href="https://www.facebook.com/kitchenconfidentialsinc" sx={{ display: 'flex', alignItems: 'center', color: 'white' }} target="_blank" rel="noopener">
                  <Facebook />
                </Link>
                <Link href="https://www.tiktok.com/@kitchenconfidentials" sx={{ display: 'flex', alignItems: 'center', color: 'white' }} target="_blank" rel="noopener">
                  <Tiktok />
                </Link>
              </Stack>
            </Box>
          </Stack>
          <Box sx={{
            paddingTop: '80px',
          }}>
            <Stack
              direction='row'
              spacing={4}
              justifyContent="center"
              alignItems="center"
              useFlexGap flexWrap="wrap"
            >
              <Typography variant="body2" color="white">© Heard. 2024</Typography>
              <CircleIcon sx={{ color: 'white', fontSize: '6px' }} />
              <StyledLink variant="body2" href="/privacy" key="privacy-policy">Privacy policy</StyledLink>
              <CircleIcon sx={{ color: 'white', fontSize: '6px' }} />
              <StyledLink variant="body2" href="/terms" key="terms-of-use">Terms of Use</StyledLink>
            </Stack>
          </Box>
        </Container>
      </Box>
    </footer>
  );
};

export default Footer;
