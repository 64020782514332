import { PaletteOptions } from '@mui/material/styles';

export const Colors: PaletteOptions = {
  background: {
    default: "#f0f2f5",
  },

  text: {
    primary: "#E44444",
    secondary: "#3E3938",
    disabled: "#777777",
  },

  common: {
    black: "#3E3938",
    white: "#FBF8F5",
  },

  primary: {
    main: "#E44444",
  },

  secondary: {
    main: "#3E3938",
  },

  info: {
    main: "#1A73E8",
  },

  success: {
    main: "#00992B",
    light: "#E5F5EA",
  },

  warning: {
    main: "#FFC700",
    light: "#FFF9E5",
  },

  error: {
    main: "#E44444",
    light: "#FBE3E3",
  },

  grey: {
    100: "#f8f9fa",
    200: "#f0f2f5",
    300: "#dee2e6",
    400: "#ced4da",
    500: "#adb5bd",
    600: "#6c757d",
    700: "#495057",
    800: "#343a40",
    900: "#212529",
  },
};
