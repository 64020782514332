import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { Page, NavBarProps } from '../../components/NavBar';

const Layout: React.FC = () => {
  const pages: Page[] = [
    { title: 'Home', route: '/home' },
    { title: 'Explore', route: '/explore' },
    { title: 'About', route: '/about' },
    { title: 'Contact', route: '/contact' },
    { title: 'Blog',  route: 'https://blog.heardnyc.com/' },
  ];

  const navBarProps: NavBarProps = {
    pages: pages,
  };

  return (
    <div className="App">
        <Header navBarProps={navBarProps} />
        <main>
          <Outlet />
        </main>
        <Footer />
    </div>
  );
};

export default Layout;
