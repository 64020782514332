import { Backdrop, Box, Popover, Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import KCButtonBase from '../../../../components/Button';

interface DotIndicatorProps {
    activeStep: number;
    totalSteps: number;
}

const DotIndicator: React.FC<DotIndicatorProps> = ({ activeStep, totalSteps }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: '24px',
                marginTop: '24px'
            }}
        >
            {
                Array.from({ length: totalSteps }, (_, index) => index)
                    .map(index =>
                    (
                        <Box
                            sx={{
                                width: '6px',
                                height: '6px',
                                borderRadius: '50%',
                                backgroundColor: activeStep === index ? 'error.main' : 'grey.300',
                                margin: '0 6px',
                            }}
                            key={index}
                        />
                    ))
            }
        </Box>
    );
};

function NewPopover({ anchorEl }: { anchorEl: any }) {
    const [showNewPopover, setShowNewPopover] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const stepsInfo = useMemo(() => [
        {
            title: "Search for Jobs",
            content: "Switch on the 'Hiring Now' toggle to view establishments that are hiring. ",
        },
        {
            title: "View open positions",
            content: "Click 'View Establishment' to see open positions",
        }
    ], []);
    const handleClose = useCallback(() => {
        setShowNewPopover(false);
    }, []);

    const handleNext = useCallback(() => {
        if (activeStep === stepsInfo.length - 1) {
            setShowNewPopover(false);
        } else {
            setActiveStep(activeStep + 1);
        }
        localStorage.setItem('new_popover_active_step', String(activeStep + 1));
    }, [activeStep, setActiveStep, stepsInfo.length]);

    const handleBack = useCallback(() => {
        if (activeStep !== 0) {
            setActiveStep(prevStep => prevStep - 1);
        }
    }, [activeStep, setActiveStep]);
    useEffect(() => {
        // TODO: cookie or localstroage check
        const newPopOverActiveStep = Number(localStorage.getItem('new_popover_active_step') || 0);
        if (newPopOverActiveStep < stepsInfo.length) {
            setShowNewPopover(true);
            setActiveStep(newPopOverActiveStep)
        }
    }, [stepsInfo.length]);
    return (<>
        <Backdrop
            open={showNewPopover}
            sx={{ zIndex: (theme) => theme.zIndex.modal - 1 }}
        />
        <Popover
            open={showNewPopover}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: isMobile ? 'center' : 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: isMobile ? 'center' : 'right',
            }}
            sx={{ zIndex: (theme) => theme.zIndex.modal - 1 }}
            elevation={3}
            slotProps={{ paper: { sx: { borderRadius: '21px', marginTop: isMobile ? '0px' : '30px' } } }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: isMobile ? '250px' : '344px',
                    height: '316px',
                    padding: '32px',
                    paddingTop: '0px',
                    boxShadow: '0px 1px 50px 0px #00000026'
                }}
            >
                <DotIndicator activeStep={activeStep} totalSteps={stepsInfo.length} />
                <Typography color="text.primary" sx={{ marginBottom: '8px', fontWeight: 700, fontSize: isMobile ? '1rem' : '1.25rem' }}>
                    NEW
                </Typography>
                <Typography color="text.secondary" sx={{ marginBottom: '8px', fontWeight: 200, fontSize: isMobile ? '1.5rem' : '2.25rem' }}>
                    {stepsInfo[activeStep].title}
                </Typography>
                <Typography color="text.secondary" sx={{ marginBottom: '16px', flex: '1 1 auto' }}>
                    {stepsInfo[activeStep].content}
                </Typography>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                }}>
                    {activeStep !== 0 && <KCButtonBase
                        color="inherit"
                        onClick={handleBack}
                        sx={{ mr: 1, width: isMobile ? '120px' : '135px' }}
                        kcvariant='outlined'
                    >
                        {activeStep === 0 ? 'Exit' : 'Back'}
                    </KCButtonBase>
                    }
                    <Box sx={{ flex: '1 1 auto' }} />
                    <KCButtonBase sx={{ width: isMobile ? '120px' : '135px' }} onClick={handleNext}>
                        {activeStep === stepsInfo.length - 1 ? 'Done' : 'Next'}
                    </KCButtonBase>
                </Box>
            </Box>
        </Popover>
    </>);
}

export default NewPopover;