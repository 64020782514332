import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AuthState {
  user: any;
  user_id: any;
  token: any;
  appstate: any;
}

const initialState: AuthState = {
  user: null,
  token: null,
  appstate: null,
  user_id: null,
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCredentials: (state, action: PayloadAction<AuthState>) => {
      const { user, token, appstate, user_id } = action.payload;
      state.user = user;
      state.token = token;
      state.appstate = appstate;
      state.user_id = user_id;
    },
  },
});

export const { setCredentials } = slice.actions;

export default slice.reducer;

export const selectCurrentUser = (state: { auth: AuthState }) => state.auth.user;
