import { Box, Chip, Grid, Stack, Typography, useTheme } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import KCStarRating from '../../../../components/KCStarRating';
import ReactGA from 'react-ga4';

export interface EstablishmentSearchCardProps {
  image: string;
  starRating: number;
  numOfReviews: number;
  name: string;
  address: string;
  baseRate: number;
  avgTips: number;
  sanitationScore: string;
  distance?: number;
  estId: string;
  gid?: string;
}

const EstablishmentSearchCard: React.FC<EstablishmentSearchCardProps> = ({
  image,
  starRating,
  name,
  address,
  baseRate,
  avgTips,
  sanitationScore,
  distance,
  numOfReviews,
  estId,
  gid,
}) => {

  const theme = useTheme();
  const itemLink = (gid !== "") ? `/establishments/new?gid=${gid}` : `/establishments/${estId}/new-review`;

  return (
    <Link to={itemLink} onClick={() => {
      ReactGA.event({
        category: "User Select Interaction",
        action: "Clicked on Establishment",
        label: `${name} - ${estId} / ${gid}`,
      });
    }} style={{ textDecoration: 'none' }}>
      <Box
        sx={{
          borderRadius: '16px',
          boxShadow: '0px 2px 20px 0px #00000026',
          backgroundColor: '#fff',
          transition: 'box-shadow 0.3s ease-in-out',
          '&:hover': {
            boxShadow: '0px 4px 30px 0px #00000040',
          },
        }}
      >
        <Grid container>
          <Grid item xs={12} md={3}>
            <Box sx={{
              backgroundImage: `url(${image})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              height: '100%',
              width: '100%',
              borderRadius: '16px 0px 0px 16px',
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'flex-end',
              [theme.breakpoints.down('md')]: {
                height: '194px',
                borderRadius: '16px 16px 0px 0px',
                alignItems: 'flex-start',
              },

            }}>
              {(distance !== undefined && distance > 0) && (
                <Chip label={distance < 1 ? distance.toFixed(2) + " miles away" : distance.toFixed(0) + " miles away"} sx={{
                  margin: '20px',
                  backgroundColor: '#fff',
                  color: theme.palette.text.secondary,
                  borderRadius: '80px',
                }} />
              )}
            </Box>
          </Grid>
          <Grid item xs={12} md={9}>
            <Box sx={{ padding: '20px' }}>
              <Stack direction={'row'} spacing={2} alignItems="center">
                <KCStarRating
                  value={starRating}
                  variant='dark'
                  readOnly
                />
                <Typography variant="body2" color="text.secondary.main" sx={{
                  color: theme.palette.text.secondary,
                }}>{starRating.toFixed(1)} stars / {numOfReviews} reviews</Typography>
              </Stack>
              <Typography variant="h6" color="text.secondary">{name}</Typography>
              <Typography variant="body2" color="text.secondary">{address}</Typography>
              <Stack direction="row" spacing={6} sx={{ marginTop: '32px' }}>
                <Box>
                  <Typography variant="body1" color="text.secondary">Base Rate</Typography>
                  <Typography variant="h6" color="text.secondary">${baseRate === 0 ? "--" : baseRate.toFixed(0)}{baseRate !== 0 && (<span style={{ fontSize: '14px' }} >/hr</span>)}</Typography>
                </Box>
                <Box>
                  <Typography variant="body1" color="text.secondary">Avg. tips</Typography>
                  <Typography variant="h6" color="text.secondary">${avgTips === 0 ? "--" : avgTips.toFixed(0)}</Typography>
                </Box>
                <Box>
                  <Typography variant="body1" color="text.secondary">Sanitation score</Typography>
                  <Typography variant="h6" color="text.secondary">{sanitationScore}</Typography>
                </Box>
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Link>
  );
};

export default EstablishmentSearchCard;
