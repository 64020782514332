// @mui material components
import { createTheme, responsiveFontSizes } from '@mui/material/styles';

// Base styles
import { Colors } from "./base/colors";
import { Typography } from "./base/typography";

// components



let theme = createTheme({
  palette: { ...Colors },
  typography: { ...Typography},
  components: {
    MuiAutocomplete: {
      styleOverrides: {
        listbox: {
          color: Colors.text?.secondary,
        }
          
        
      }
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          '&.MuiContainer-root': {
            maxWidth: '1280px',
          },
        },
      },
    },
  }
});

theme = responsiveFontSizes(theme);

export default theme;