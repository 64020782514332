
import React, { useState } from 'react';
import ReviewCard from '../ReviewCard';
import { Box, Pagination, Skeleton, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useGetPublicEstablishmentReviewsQuery, Review, useGetEstablishmentReviewsQuery } from '../../../../store/kcApi';
import { ReactComponent as PizzaGuy } from '../../../../assets/svg/pizza_guy.svg';
import KCButtonBase from '../../../../components/Button';
import { useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga4';
import LockedReviewCard from '../ReviewCard/lockedCard';


interface ReviewsProps {
  establishmentId: string;
  isLoading: boolean;
  totalReviews: number;
  establishmentName: string;
  isAuthenticated: boolean;
}

// TODO: DRY this up - duplicated these quickly because of the different queries
// and the restrictions on conditional hooks

export const PublicReviews: React.FC<ReviewsProps> = (props) => {

  const numberOfReviews = 3;
  const [page] = useState(1);

  const navigate = useNavigate();
  const theme = useTheme();

  const {
    data: rawReviews,
    error: reviewsError,
    isLoading: loadingReviews
  } = useGetPublicEstablishmentReviewsQuery({ establishmentId: props.establishmentId || '', limit: numberOfReviews, page: page })

  if (props.isLoading || loadingReviews) {
    return (
      <Stack spacing={4}>
        <Skeleton variant="rounded" width='40vw' height={210} />
        <Skeleton variant="rounded" width='40vw' height={210} />
        <Skeleton variant="rounded" width='40vw' height={210} />
      </Stack>
    );
  } else if (reviewsError !== undefined) {
    return (
      <Box>
        <Typography variant='h5' sx={{ color: 'red' }}>
          Error
        </Typography>
      </Box>
    );
  }

  if (!rawReviews?.data || rawReviews?.data.length === 0) {
    return (
      <Box>
        <Typography
          variant='subtitle2'
          sx={{
            textTransform: 'uppercase',
            color: '#837A78',
            fontSize: '20px',
            padding: '0px 0px 20px 0px',
          }}
        >
          Reviews
        </Typography>
        <Box
          sx={{
            backgroundColor: '#FFFFFF',
            padding: '40px',
            borderRadius: '18px'
          }}
        >
          <Stack spacing={3} justifyContent="center" alignItems="center">
            <Typography variant='body1' color='text.secondary' textAlign='center'>
              No reviews for {props.establishmentName} yet.
            </Typography>
            <Box sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
            }}>
              <PizzaGuy style={{
                [theme.breakpoints.down('md')]: {
                  width: '96%',
                  height: 'auto'
                },
              }} />
            </Box>
            <KCButtonBase
              sx={{
                width: '319px'
              }}
              onClick={
                () => {
                  ReactGA.event({
                    category: "User Interaction",
                    action: "Click",
                    label: "Write First Review",
                  });
                  navigate(`${process.env.REACT_APP_OVERRIDE_PUBLIC_URL}/establishments/${props.establishmentId}/new-review`);
                }
              }
            >
              Be the first to write a review
            </KCButtonBase>
          </Stack>
        </Box>
      </Box>
    );
  }

  return (
    <Box>
      <Typography
        variant='subtitle2'
        sx={{
          textTransform: 'uppercase',
          color: '#837A78',
          fontSize: '20px',
          padding: '0px 0px 20px 0px',
        }}
      >
        Reviews
      </Typography>
      <Stack spacing={3}>
        {rawReviews?.data?.map((review: Review) => (
          <ReviewCard
            key={review.id}
            review={review.review || ''}
            rating={review.overall_rating || 0}
            baseRate={review.wage || 0}
            wageType={review.wage_type || 'hourly'}
            avgTips={review.avg_tip_take || 0}
            createdDate={review.createdAt || ''}
            id={review.id || ''}
          />
        ))}
        {!props.isAuthenticated && (
          <LockedReviewCard
            key="locked-1"
          />
        )}
      </Stack>
    </Box>
  );
};

export const PrivateReviews: React.FC<ReviewsProps> = (props) => {
  const numberOfReviews = 3;
  const [page, setPage] = useState(1);

  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleChange = (event: React.ChangeEvent<unknown>, page: number) => {
    setPage(page);
    window.scrollTo(0, isMobile ? 1200 : 600);
  };

  const {
    data: rawReviews,
    error: reviewsError,
    isLoading: loadingReviews
  } = useGetEstablishmentReviewsQuery({ establishmentId: props.establishmentId || '', limit: numberOfReviews, page: page })

  if (props.isLoading || loadingReviews) {
    return (
      <Stack spacing={4}>
        <Skeleton variant="rounded" width='40vw' height={210} />
        <Skeleton variant="rounded" width='40vw' height={210} />
        <Skeleton variant="rounded" width='40vw' height={210} />
      </Stack>
    );
  } else if (reviewsError !== undefined) {
    return (
      <Box>
        <Typography variant='h5' sx={{ color: 'red' }}>
          Error
        </Typography>
      </Box>
    );
  }

  if (!rawReviews?.data || rawReviews?.data.length === 0) {
    return (
      <Box>
        <Typography
          variant='subtitle2'
          sx={{
            textTransform: 'uppercase',
            color: '#837A78',
            fontSize: '20px',
            padding: '0px 0px 20px 0px',
          }}
        >
          Reviews
        </Typography>
        <Box
          sx={{
            backgroundColor: '#FFFFFF',
            padding: '40px',
            borderRadius: '18px'
          }}
        >
          <Stack spacing={3} justifyContent="center" alignItems="center">
            <Typography variant='body1' color='text.secondary' textAlign='center'>
              No reviews for {props.establishmentName} yet.
            </Typography>
            <Box sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
            }}>
              <PizzaGuy style={{
                [theme.breakpoints.down('md')]: {
                  width: '96%',
                  height: 'auto'
                },
              }} />
            </Box>
            <KCButtonBase
              sx={{
                width: '319px'
              }}
              onClick={
                () => {
                  ReactGA.event({
                    category: "User Interaction",
                    action: "Click",
                    label: "Write First Review",
                  });
                  navigate(`${process.env.REACT_APP_OVERRIDE_PUBLIC_URL}/establishments/${props.establishmentId}/new-review`);
                }
              }
            >
              Be the first to write a review
            </KCButtonBase>
          </Stack>
        </Box>
      </Box>
    );
  }

  return (
    <Box>
      <Typography
        variant='subtitle2'
        sx={{
          textTransform: 'uppercase',
          color: '#837A78',
          fontSize: '20px',
          padding: '0px 0px 20px 0px',
        }}
      >
        Reviews
      </Typography>
      <Stack spacing={3}>
        {rawReviews?.data?.map((review: Review) => (
          <ReviewCard
            key={review.id}
            review={review.review || ''}
            rating={review.overall_rating || 0}
            baseRate={review.wage || 0}
            wageType={review.wage_type || 'hourly'}
            avgTips={review.avg_tip_take || 0}
            createdDate={review.createdAt || ''}
            id={review.id || ''}
          />
        ))}
        {!props.isAuthenticated && (
          <LockedReviewCard
            key="locked-1"
          />
        )}
        {props.totalReviews > numberOfReviews && (
          <Pagination count={Math.ceil(props.totalReviews / numberOfReviews)} page={page} size="small" onChange={handleChange} />
        )}
      </Stack>
    </Box>

  );
}