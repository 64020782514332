import { User, useAuth0 } from '@auth0/auth0-react';
import { Box, Button, Container, IconButton, Stack, Toolbar, Typography } from '@mui/material';
import Drawer, { DrawerProps } from '@mui/material/Drawer';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Page } from '../..';
import { ReactComponent as ExitIcon } from '../../../../assets/svg/icons/exit_icon.svg';

interface MobileDrawerProps extends DrawerProps {
  pages: Page[],
  isauthenticated: boolean,
  user?: User,
  setOpenLoginDrawer: Function,
}

const MobileDrawer: React.FC<MobileDrawerProps> = ({ pages, isauthenticated, user, setOpenLoginDrawer, ...props }) => {

  const { loginWithRedirect } = useAuth0();

  let navigate = useNavigate();

  const drawer = (
    <Box>
      <Toolbar>
        <Box sx={{ flexGrow: 1 }} />
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={() => {
            if (props.onClose) {
              props.onClose({} as React.MouseEvent<HTMLButtonElement, MouseEvent>, 'backdropClick');
            }
            // Add code to close the drawer here
          }}
          sx={{ flexGrow: 0, mr: 2, display: { md: 'none' } }}
        >
          <ExitIcon style={{ color: "text.secondary" }} />
        </IconButton>
      </Toolbar>
      <Container>
        <Box>
          <Stack
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={2}
          >
            {pages.map((page) => (
              <Button
                key={page.route}
                onClick={() => {
                  if (page.route === '/explore' && !isauthenticated)
                    setOpenLoginDrawer(true)
                  if (page.route.includes('https'))
                    window.open(page.route, '_blank');
                  else navigate(page.route);
                  
                  if (props.onClose) {
                    props.onClose({} as React.MouseEvent<HTMLButtonElement, MouseEvent>, 'backdropClick');
                  }
                }}
                sx={{
                  color: 'text.secondary',
                  textTransform: 'none',
                  '&:hover': {
                    backgroundColor: 'transparent',
                    textDecoration: 'underline',
                    textDecorationColor: '#FFFFFF',
                  },
                }}
              >
                <Typography variant="h2" color="common.white">
                  {page.title}
                </Typography>
              </Button>
            ))}
            <Button
              key="login"
              onClick={() => {
                if (isauthenticated) {
                  navigate('/profile');
                }
                loginWithRedirect({
                  appState: {
                    returnTo: window.location.href,
                  },
                });
              }}
              sx={{
                color: 'text.secondary',
                textTransform: 'none',
                paddingTop: '100px',
                '&:hover': {
                  backgroundColor: 'transparent',
                  textDecoration: 'underline',
                  textDecorationColor: '#FFFFFF',
                },
              }}
            >
              <Typography variant="h2" color="common.white">
                {isauthenticated ? "Hi, " + user?.nickname : "Login"}
              </Typography>
            </Button>
          </Stack>
        </Box>
      </Container>
    </Box>
  );

  return (
    <Drawer {...props}>{drawer}</Drawer>
  )
};

export default MobileDrawer;
