
import { Close, Favorite, NavigateBefore } from '@mui/icons-material';
import { Box, IconButton, Pagination, Skeleton, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import establishmentBkg from "../../../assets/placeholders/establishment.jpg";
import { ReactComponent as ApplyIcon } from "../../../assets/svg/icons/appy_icon.svg";
import { ReactComponent as SaveIcon } from "../../../assets/svg/icons/save_icon.svg";
import { ReactComponent as PizzaGuy } from '../../../assets/svg/pizza_guy.svg';
import KCButtonBase from '../../../components/Button';
import { JobListing, useDeleteUserSavedJobListingsMutation } from "../../../store/kcApi";
import { deleteSavedJob } from "../../../store/savedJobsSlice";
import { RootState } from '../../../store/store';
import { getPositionWageInfo } from '../../../utils/helpers';
import PositionDrawer from './PositionDrawer';

const SavedJobs: React.FC = () => {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const dispatch = useDispatch();
  const [openDrawer, setopenDrawer] = useState(false);
  const [selectedPosition, setSelectedPosition] = useState<JobListing | null>(null);
  const { jobList: savedJobList, loadingSavedJobs, savedJobsError } = useSelector((state: RootState) => state.savedJobs);

  const [deleteUserSavedJobListings] = useDeleteUserSavedJobListingsMutation();
  const currentUser = useSelector((state: RootState) => state.auth);

  const numberOfSavedJobsOnPage = 5;//KTest: This value should be 10
  const [page, setPage] = useState(1);
  const totalPageCount = Math.ceil(savedJobList.length / numberOfSavedJobsOnPage);
  const startIndex = (page - 1) * numberOfSavedJobsOnPage;
  const endIndex = Math.min(startIndex + numberOfSavedJobsOnPage, savedJobList.length);
  const slicedSavedJob = savedJobList.slice(startIndex, endIndex);
  if (page !== 1 && slicedSavedJob.length === 0) {
    setPage(page - 1);
  }

  // callbacks and functions
  const unsavePosition = (position: JobListing) => {
    deleteUserSavedJobListings({ userId: currentUser.user_id, saveJobListing: { job_listing_id: String(position.id) } })
      .unwrap()
      .then(payload => {
        dispatch(deleteSavedJob(position))
        setopenDrawer(false);
      })
      .catch(err => console.error('deleteUserSavedJobListings', err));
  }
  const handleUnSave = () => {
    if (selectedPosition) {
      unsavePosition(selectedPosition);
    }
  }
  const handleChangePage = (event: React.ChangeEvent<unknown>, page: number) => {
    setPage(page);
  };

  if (loadingSavedJobs || currentUser.user_id === null) {
    return (
      <Stack spacing={4}>
        <Skeleton variant="rounded" width='40vw' height={210} />
        <Skeleton variant="rounded" width='40vw' height={210} />
        <Skeleton variant="rounded" width='40vw' height={210} />
      </Stack>
    );
  } else if (savedJobsError !== undefined) {
    return (
      <Box>
        <Typography variant='h5' sx={{ color: 'red' }}>
          Error
        </Typography>
      </Box>
    );
  }
  return (
    <Box>
      {
        savedJobList.length === 0 ?
          <Box
            sx={{
              backgroundColor: '#FFFFFF',
              padding: '40px',
            }}
          >
            <Stack spacing={3} justifyContent="center" alignItems="center">
              <Typography variant='body1' color='text.secondary'>
                No Saved Jobs yet.
              </Typography>
              <Box>
                <PizzaGuy />
              </Box>
            </Stack>
          </Box> :
          <>
            <Stack>
              {slicedSavedJob.map((position, idx) => (
                <Box
                  key={idx}
                  sx={{
                    minWidth: '180px',
                    padding: '32px',
                    cursor: 'pointer',
                    borderBottom: '1px solid',
                    borderBottomColor: 'divider',
                    '&:hover': {
                      background: 'rgba(251, 251, 250, 1)'
                    },
                  }}
                  onClick={() => { setSelectedPosition(position); setopenDrawer(true); }}
                >
                  <Stack>
                    <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
                      <Stack>
                        <Typography variant="body2" color="text.secondary">1 week ago</Typography>
                        <Typography variant="h6" fontWeight={600} color="text.secondary">{position.role}</Typography>
                      </Stack>
                      <IconButton onClick={(event) => {
                        event.stopPropagation();
                        unsavePosition(position);
                      }}>
                        <Favorite color="primary" />
                      </IconButton>
                    </Stack>
                    <Stack mt={2}>
                      <Typography variant="body1" color="text.secondary">{position.wageType === 'yearly' ? 'Full time • Salary' : 'Full/Part time • Hourly'}</Typography>
                      <Typography variant="body1" color="text.secondary" fontWeight={600}>{getPositionWageInfo(position)}</Typography>
                      <Typography variant="body1" color="text.secondary">Experience: {position.yearsOfExperience} years</Typography>
                    </Stack>
                  </Stack>
                </Box>
              ))}
            </Stack>
            <Box display="flex" justifyContent="center" mt={2}>
              <Pagination
                count={totalPageCount}
                page={page}
                size="medium"
                onChange={handleChangePage}
              />
            </Box>
          </>
      }
      {
        selectedPosition && (
          isMobile === false ?
            (<PositionDrawer
              anchor="right"
              open={openDrawer}
              onClose={() => setopenDrawer(false)}
            >
              <Box width='50vw' display='flex'>
                <Box padding='48px' minWidth='562px' position='relative'>
                  <IconButton sx={{ position: 'absolute', right: '20px', top: '48px' }} onClick={() => { setopenDrawer(false); }}>
                    <Close width={16} height={16} />
                  </IconButton>
                  <Stack direction="row" gap='40px'>
                    <Box
                      component="img"
                      sx={{
                        height: '135px',
                        width: '135px',
                        borderRadius: "20px",
                      }}
                      alt="The house from the offer."
                      src={establishmentBkg}
                      className="establishment-img"
                    />
                    <Stack>
                      <Typography variant="h6" fontWeight={600} color="text.secondary">{selectedPosition.role}</Typography>
                      <Stack mt={2}>
                        <Typography variant="body1" color="text.secondary">{selectedPosition.wageType === 'yearly' ? 'Full time • Salary' : 'Full/Part time • Hourly'}</Typography>
                        <Typography variant="body1" color="text.secondary" fontWeight={600}>{getPositionWageInfo(selectedPosition)}</Typography>
                        <Typography variant="body1" color="text.secondary">Experience: {selectedPosition.yearsOfExperience} years</Typography>
                      </Stack>
                    </Stack>
                  </Stack>
                  <Stack mt={8} direction="row" gap={3}>
                    <KCButtonBase sx={{ width: '260px' }}>Apply&nbsp;<ApplyIcon /></KCButtonBase>
                    <KCButtonBase sx={{ width: '260px' }} kcvariant="outlined" onClick={handleUnSave}>Unsave&nbsp;<SaveIcon /></KCButtonBase>
                  </Stack>
                  <Stack mt={4}>
                    <Typography variant="body1" color="text.secondary" width={1} style={{ whiteSpace: 'pre-wrap' }}>{selectedPosition.listingDetails}</Typography>
                  </Stack>
                </Box>
              </Box>
            </PositionDrawer>) :
            (<PositionDrawer
              anchor="bottom"
              open={openDrawer}
              onClose={() => setopenDrawer(false)}
              PaperProps={{
                sx: {
                  borderRadius: '32px 32px 0 0',
                  top: '70px'
                }
              }}
            >
              <Box>
                <Box bgcolor='#F4F3F2' pt="51px" pb="16px" px="16px" display="flex" gap={1}
                  onClick={() => { setopenDrawer(false); }}
                >
                  <NavigateBefore />
                  <Typography variant="body1" color="primary">Back</Typography>
                </Box>
                <Box padding='16px'>
                  <Stack direction="row" gap='40px'>
                    <Box
                      component="img"
                      sx={{
                        height: '108px',
                        width: '108px',
                        borderRadius: "20px",
                      }}
                      alt="The house from the offer."
                      src={establishmentBkg}
                      className="establishment-img"
                    />
                    <Stack>
                      <Typography variant="h6" fontWeight={600} color="text.secondary">{selectedPosition.role}</Typography>
                      <Stack mt={2}>
                        <Typography variant="body1" color="text.secondary">{selectedPosition.wageType === 'yearly' ? 'Full time • Salary' : 'Full/Part time • Hourly'}</Typography>
                        <Typography variant="body1" color="text.secondary" fontWeight={600}>{getPositionWageInfo(selectedPosition)}</Typography>
                        <Typography variant="body1" color="text.secondary">Experience: {selectedPosition.yearsOfExperience} years</Typography>
                      </Stack>
                    </Stack>
                  </Stack>
                  <Stack mt={4} gap={3}>
                    <KCButtonBase>Apply&nbsp;<ApplyIcon /></KCButtonBase>
                    <KCButtonBase kcvariant="outlined" onClick={handleUnSave}>Unsave&nbsp;<SaveIcon /></KCButtonBase>
                  </Stack>
                  <Stack mt={4}>
                    <Typography variant="body1" color="text.secondary">{selectedPosition.listingDetails}</Typography>
                  </Stack>
                </Box>
              </Box>
            </PositionDrawer>)
        )
      }
    </Box>
  );
};

export default SavedJobs;