import { Alert, AlertColor, Box, Grid, Stack, Tooltip, Typography } from "@mui/material";
import KCTextField from "../../../components/KCTextField";
import KCButtonBase from "../../../components/Button";
import KCDialog from "../../../components/KCDialog";
import { useEffect, useState } from "react";
import { RootState } from "../../../store/store";
import { useSelector } from "react-redux";
import * as yup from 'yup';
import { GetUserWithIdApiArg, ResetUserPasswordApiArg, UpdateUserApiArg, useGetUserWithIdQuery, useResetUserPasswordMutation, useUpdateUserMutation } from "../../../store/kcApi";
import { Form, Formik, FormikHelpers } from "formik";

// Define the type for the function
type LoginTypeChecker = (userId: string) => boolean;

// Function to determine if the login method was via a social provider
const isSocialLogin: LoginTypeChecker = (userId) => {
  // List of known social login prefixes
  const socialProviders = ['google-oauth2', 'facebook'];

  // Check if the user ID contains any of the social provider prefixes
  return socialProviders.some(provider => userId.startsWith(provider + '|'));
};

const PersonalDetails: React.FC = () => {
  const currentUser = useSelector((state: RootState) => state.auth);

  const {
    data: kcUser,
  } = useGetUserWithIdQuery(
    { userId: currentUser.user_id } as GetUserWithIdApiArg,
  );
  const [resetPasswordAvailable, setResetPasswordAvailable] = useState(true)

  const [userId, setUserId] = useState<ResetUserPasswordApiArg>({ userId: '' })

  const [resetPassword] = useResetUserPasswordMutation();
  const [openResetPassword, setOpenResetPassword] = useState(false)
  const handleResetPassword = () => {
    resetPassword(userId)
  }

  useEffect(() => {
    if (currentUser.user_id !== null) setUserId({ userId: currentUser.user_id });
    // check if user used social login
    if (currentUser.user)
      if (isSocialLogin(currentUser.user)) {
        setResetPasswordAvailable(false)
      } else {
        setResetPasswordAvailable(true)
      }

  }, [currentUser])

  const [initialValues, setInitialValues] = useState({ first_name: '', last_name: '', nickname: '', email_address: '', phone_number: '' });

  useEffect(() => {
    setInitialValues({
      first_name: kcUser?.first_name || '',
      last_name: kcUser?.last_name || '',
      nickname: kcUser?.nickname || '',
      email_address: kcUser?.email_address || '',
      phone_number: kcUser?.phone_number || '',
    })
  }, [kcUser]);

  const validationSchema = yup.object().shape({
    first_name: yup.string().min(5).required('Name is required'),
    last_name: yup.string().min(5).required('Name is required'),
    email_address: yup.string().email().required('Email is required'),
    nickname: yup.string().min(5).required('Name is required'),
  });

  interface alertValues {
    type: AlertColor,
    message: string,
  }

  const [alert, setAlert] = useState<alertValues>({} as alertValues);
  const [alertOpen, setAlertOpen] = useState(false);
  const [updateUser] = useUpdateUserMutation();

  const handleSubmit = (values: any, formikHelpers: FormikHelpers<any>) => {
    const userUpdateRequest: UpdateUserApiArg = {
      userId: currentUser.user_id,
      updateUser: {
        first_name: values.first_name,
        last_name: values.last_name,
        email_address: values.email_address,
        nickname: values.nickname,
        phone_number: values.phone_number
      }
    }

    updateUser(userUpdateRequest).unwrap()
      .then((response) => {
        setAlert({ type: "success", message: "User profile updated successfully!" });
        setAlertOpen(true);
        formikHelpers.resetForm();
        window.scrollTo(0, 0);

        setInitialValues({
          first_name: values.first_name,
          last_name: values.last_name,
          email_address: values.email_address,
          nickname: values.nickname,
          phone_number: values.phone_number || '',
        })
      })
      .catch((error) => {
        setAlert({ type: "error", message: "An error occurred while updating the User profile!" });
        setAlertOpen(true);
        window.scrollTo(0, 0);
      })
  }

  return (
    <Stack width={1} height={1} justifyContent="space-between">
      {alertOpen && <Alert sx={{ mx: 2 }} severity={alert.type} onClose={() => setAlertOpen(false)}>{alert.message}</Alert>}
      <KCDialog
        customTitle="Password reset"
        customContent={`A recovery link has been sent to <b>${kcUser?.email_address}.</b>`}
        open={openResetPassword}
        onClose={() => setOpenResetPassword(false)}
      />
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        enableReinitialize
        validationSchema={validationSchema}
      >
        {formik => (
          <Form>
            <Box p={4}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Stack>
                    <Typography variant="body1" color="text.secondary" sx={{ fontWeight: "bold", marginBottom: "4px" }}>First name</Typography>
                    <KCTextField
                      id="first_name"
                      name="first_name"
                      variant="outlined"
                      placeholder='Enter your first name...'
                      value={formik.values.first_name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.first_name && Boolean(formik.errors.first_name)}
                      helperText={formik.touched.first_name && formik.errors.first_name}
                      fullWidth
                    />
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Stack>
                    <Typography variant="body1" color="text.secondary" sx={{ fontWeight: "bold", marginBottom: "4px" }}>Last name</Typography>
                    <KCTextField
                      id="last_name"
                      name="last_name"
                      variant="outlined"
                      placeholder='Enter your last name...'
                      value={formik.values.last_name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.last_name && Boolean(formik.errors.last_name)}
                      helperText={formik.touched.last_name && formik.errors.last_name}
                      fullWidth
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Stack>
                    <Typography variant="body1" color="text.secondary" sx={{ fontWeight: "bold", marginBottom: "4px" }}>Nick name</Typography>
                    <KCTextField
                      id="nickname"
                      name="nickname"
                      variant="outlined"
                      placeholder='Enter your nickname...'
                      value={formik.values.nickname}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.nickname && Boolean(formik.errors.nickname)}
                      helperText={formik.touched.nickname && formik.errors.nickname}
                      fullWidth
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Stack>
                    <Typography variant="body1" color="text.secondary" sx={{ fontWeight: "bold", marginBottom: "4px" }}>Email</Typography>
                    <KCTextField
                      id="email_address"
                      name="email_address"
                      variant="outlined"
                      placeholder='Enter your email...'
                      value={formik.values.email_address}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.email_address && Boolean(formik.errors.email_address)}
                      helperText={formik.touched.email_address && formik.errors.email_address}
                      fullWidth
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Stack>
                    <Typography variant="body1" color="text.secondary" sx={{ fontWeight: "bold", marginBottom: "4px" }}>Phone</Typography>
                    <KCTextField
                      id="phone_number"
                      name="phone_number"
                      variant="outlined"
                      placeholder='Enter your number...'
                      value={formik.values.phone_number}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.phone_number && Boolean(formik.errors.phone_number)}
                      helperText={formik.touched.phone_number && formik.errors.phone_number}
                      fullWidth
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  {resetPasswordAvailable ? (<Typography
                    variant="body1"
                    color="primary"
                    sx={{
                      fontWeight: "bold",
                      marginBottom: "4px",
                      cursor: "pointer",
                      textDecoration: "underline",
                    }}
                    onClick={() => {
                      handleResetPassword();
                      setOpenResetPassword(true)
                    }}
                  >Reset password</Typography>) :
                    (<Tooltip title="Signed in with a social login" placement="bottom-start">
                      <Typography
                        variant="body1"
                        color="text.disabled"
                        sx={{
                          marginBottom: "4px",
                          textDecoration: "underline",
                        }}
                      >
                        Reset password
                      </Typography>
                    </Tooltip>)
                  }
                </Grid>
              </Grid>
            </Box>
            <Stack
              direction="row"
              p={4}
              justifyContent="space-between"
              sx={{ borderTop: 1, borderColor: 'divider' }}
            >
              <KCButtonBase variant="outlined" kcvariant="outlined">Discard Changes</KCButtonBase>
              <KCButtonBase
                variant="contained"
                type="submit"
                disabled={!formik.isValid}
                onClick={formik.submitForm}
              >Save Changes</KCButtonBase>
            </Stack>
          </Form>
        )}
      </Formik>
    </Stack>
  )
}

export default PersonalDetails;