import { Box, Dialog, DialogContent, DialogProps, DialogTitle, IconButton, Stack, Typography, styled } from '@mui/material';
import React, { useState } from 'react';

import { ReactComponent as ShareEmail } from '../../assets/svg/Socials/share_email.svg';
import { ReactComponent as ShareFacebook } from '../../assets/svg/Socials/share_facebok.svg';
import { ReactComponent as ShareReddit } from '../../assets/svg/Socials/share_reddit.svg';
import { ReactComponent as ShareText } from '../../assets/svg/Socials/share_text.svg';
import { ReactComponent as ShareTwitter } from '../../assets/svg/Socials/share_twitter.svg';
import { ReactComponent as ShareWhatsApp } from '../../assets/svg/Socials/share_whats.svg';
import { ReactComponent as ExitIcon } from '../../assets/svg/icons/exit_icon_dark.svg';
import KCButtonBase from '../Button';

import {
  EmailShareButton,
  FacebookShareButton,
  RedditShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share';


interface KCShareDialogProps extends DialogProps {
  // Add any additional props you need for your custom dialog
  // For example, you can add a custom title or content
  customTitle: string;
  currentUrl: string;
}

const StyledDialog = styled(Dialog)<DialogProps>(({ theme }) => ({

  '& .MuiDialog-paper': {
    borderRadius: '32px',
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    padding: '24px',
    width: '120vw',
  },
}));

const KCShareDialog: React.FC<KCShareDialogProps> = ({
  customTitle,
  currentUrl,
  ...dialogProps
}) => {

  const [copyState, setCopyState] = useState('Copy')

  async function copyToClip() {
    await navigator.clipboard.writeText(currentUrl);
    setCopyState('Copied')
  }

  return (
    <StyledDialog {...dialogProps}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'end',
          justifyContent: 'flex-end',
        }}
      >
        <IconButton onClick={() => {
          setCopyState('Copy')
          dialogProps.onClose && dialogProps.onClose({}, 'backdropClick')
        }
        }>
          <ExitIcon />
        </IconButton>
      </Box>
      <DialogTitle
        sx={{
          textAlign: 'center',
          color: '#3E3938',
        }}>
        Share {customTitle}
      </DialogTitle>
      <DialogContent
        sx={{
          textAlign: 'center',
          color: '#3E3938',
          mb: '24px',
        }}
      >
        <Stack direction='row' spacing={6} useFlexGap flexWrap="wrap" justifyContent="center">
          <Stack direction='column' spacing={2}>
            <FacebookShareButton url={currentUrl}>
              <ShareFacebook />
            </FacebookShareButton>

            <Typography variant="body2" color="text.secondary">Facebook</Typography>
          </Stack>
          <Stack direction='column' spacing={2}>
            <TwitterShareButton url={currentUrl}>
              <ShareTwitter />
            </TwitterShareButton>
            <Typography variant="body2" color="text.secondary">X</Typography>
          </Stack>
          <Stack direction='column' spacing={2}>
            <RedditShareButton url={currentUrl}>
              <ShareReddit />
            </RedditShareButton>
            <Typography variant="body2" color="text.secondary">Reddit</Typography>
          </Stack>
          <Stack direction='column' spacing={2}>
            <WhatsappShareButton url={currentUrl}>
              <ShareWhatsApp />
            </WhatsappShareButton>
            <Typography variant="body2" color="text.secondary">WhatsApp</Typography>
          </Stack>
          <Stack direction='column' spacing={2}>
            <IconButton href={`sms:[phone number]&body=Check out this review! ${currentUrl}`}>
              <ShareText width="70px" height="73.5px" />
            </IconButton>
            <Typography variant="body2" color="text.secondary">Text Message</Typography>
          </Stack>
          <Stack direction='column' spacing={2}>
            <EmailShareButton url={currentUrl}>
              <ShareEmail />
            </EmailShareButton>
            <Typography variant="body2" color="text.secondary">Email</Typography>
          </Stack>
          <KCButtonBase
            color='primary'
            size='large'
            onClick={copyToClip}
            fullWidth
          >
            {copyState === 'Copied' ? 'Copied link!' : 'Copy link to clipboard'}
          </KCButtonBase>
        </Stack>

      </DialogContent>
    </StyledDialog>
  );
};

export default KCShareDialog;