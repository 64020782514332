import {
  Box,
  Divider,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import KCButtonBase from "../../../../components/Button";
import KCSearchFieldWithIcon from "../../../../components/KCSearchFieldWithIcon";
import { useEffect, useMemo, useState } from "react";
import { GetPublicEstablishmentsApiArg } from "../../../../store/kcApi";
import { useDispatch } from "react-redux";
import { initMapState, setMapState } from "../../../../store/mapSlice";
import { setFilterState } from "../../../../store/filterSlice";

interface FilterProps {
  fieldValue: string,
  setSearchParams: Function;
}

const Filters: React.FC<FilterProps> = ({
  fieldValue,
  setSearchParams
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();

  const initialFilterOptions: GetPublicEstablishmentsApiArg = useMemo(() => ({}), []);

  const [filterOptions, setFilteroptions] = useState<GetPublicEstablishmentsApiArg>(initialFilterOptions);

  useEffect(() => {
    setSearchParams(initialFilterOptions)
  }, [setSearchParams, initialFilterOptions]);

  useEffect(() => {
    if (fieldValue !== '') {
      filterOptions.name = fieldValue
      handleSearch()
    }
  })

  const handleSearch = () => {
    dispatch(setFilterState({ name: filterOptions.name }));
    if (filterOptions.name === '' || filterOptions.name === undefined) {
      dispatch(setMapState({ lockBounds: false }))
      dispatch(initMapState())
      setSearchParams((prevSearchParams: GetPublicEstablishmentsApiArg) => ({
        ...prevSearchParams,
        name: filterOptions.name,
      }))
    }
    else {
      dispatch(setMapState({ lockBounds: true }))
      setSearchParams((prevSearchParams: GetPublicEstablishmentsApiArg) => ({
        ...prevSearchParams,
        name: filterOptions.name,
        northEastLatitude: undefined,
        northEastLongitude: undefined,
        southWestLatitude: undefined,
        southWestLongitude: undefined,
      }))
    }
  }

  return !isMobile ? (
    <Stack
      divider={<Divider />}
      sx={{
        padding: "40px 48px 24px",
        borderBottom: "1px solid #E4E0DF;",
        borderRadius: '32px 32px 0 0',
        background: '#ffffff'
      }}
      spacing={3}
    >
      <Stack spacing={2}>
        <Stack>
          <Typography variant="body1" color="text.secondary" sx={{ fontWeight: 600, marginBottom: "6px" }}>
            Search for establishments
          </Typography>
          <Grid container spacing={3} alignItems="center">
            <Grid item md={9}>
              <KCSearchFieldWithIcon
                fullWidth
                outlined={true}
                placeholder="Start typing..."
                value={fieldValue || filterOptions.name}
                onChange={(e) => setFilteroptions({ ...filterOptions, name: e.target.value })}
                onKeyUp={(e) => { if (e.key === 'Enter') handleSearch(); }}
                onSubmit={() => handleSearch()}
                onClear={() => { setFilteroptions({ ...filterOptions, name: '' }); handleSearch(); }}
              />
            </Grid>
            <Grid item md={3}>
              <KCButtonBase onClick={handleSearch} fullWidth>Search</KCButtonBase>
            </Grid>
          </Grid>
        </Stack>
      </Stack>
    </Stack>
  ) : (
    <Box
      sx={{
        padding: "0px 16px 16px",
      }}
    >
      <Stack spacing={2}>
        <Stack>
          <Typography variant="body1" sx={{ fontWeight: 600 }}>Search for establishments</Typography>
          <Stack direction="row" justifyContent="space-between">
            <KCSearchFieldWithIcon
              fullWidth
              outlined={true}
              placeholder="Start typing..."
              value={filterOptions.name}
              onChange={(e) => { setFilteroptions({ ...filterOptions, name: e.target.value }) }}
              onKeyUp={(e) => { if (e.key === 'Enter') handleSearch(); }}
              onSubmit={handleSearch}
            />
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
};
export default Filters;