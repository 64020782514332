import React from 'react';

import { Auth0Provider, Auth0ProviderOptions, } from '@auth0/auth0-react';

interface AuthProviderWithRedirectHandlingProps {
	children: React.ReactNode;
}

export const AuthProviderWithRedirectHandling: React.FC<AuthProviderWithRedirectHandlingProps> = ({ children }) => {

	// Custom handling of success to navigate to the appropriate location via React Router
	const handleRedirect = (appstate: any) => {
		const baseUrl = `${process.env.REACT_APP_OVERRIDE_PUBLIC_URL}/redirect`;

		// Check if appstate has a redirect URL
		if (appstate && appstate.returnTo) {
			// Encode the redirect URL to ensure it's safe for inclusion in a URL
			const encodedRedirectUri = encodeURIComponent(appstate.returnTo);

			// Redirect to the specified URL with the redirectUri as a query parameter
			window.location.replace(`${baseUrl}?redirectUri=${encodedRedirectUri}`);
		} else {
			// If no redirect URL is specified in appstate, just redirect to the base URL
			window.location.replace(baseUrl);
		}
	};

	const providerConfig: Auth0ProviderOptions = {
		domain: process.env.REACT_APP_AUTH0_DOMAIN || '',
		clientId: process.env.REACT_APP_AUTH0_CLIENT_ID || '',
		authorizationParams: {
			redirect_uri: window.location.origin,
			audience: process.env.REACT_APP_AUTH0_AUDIENCE,
		},
	};

	return (
		<Auth0Provider onRedirectCallback={handleRedirect}
			{...providerConfig}
		>
			{children}
		</Auth0Provider>
	);
}