import { Box, Modal, ModalProps, styled } from "@mui/material";

const StyledBox = styled(Box)(({ theme }) => ({
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  borderRadius: '32px',
  backgroundColor: '#FFFFFF',
  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
  outline: 'none',
  padding: '50px',
  maxWidth: '570px',
  [theme.breakpoints.down('md')]: {
    padding: "20px",
    width: '300px'
  },
}));

const KCModal: React.FC<ModalProps> = (props) => {
  return (
    <Modal {...props}>
      <StyledBox>
        {props.children}
      </StyledBox>
    </Modal>
  )
}

export default KCModal;