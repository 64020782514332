import { Box, Stack, useMediaQuery, useTheme } from "@mui/material";
import KCButtonBase from "../../../components/Button";
import KCDialog from "../../../components/KCDialog";
import { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";

const Account: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [logoutDialog, setLogoutDialogOpen] = useState(false);
  const [deleteAccountDialog, setDeleteAccountDialogOpen] = useState(false);
  const { logout } = useAuth0();

  return (
    <>
      <Box p={isMobile ? 2: 4}>
        <Stack spacing={4}>
          <KCButtonBase
            variant="contained"
            sx={{ width: isMobile ? '100%': '400px' }}
            onClick={() => { setLogoutDialogOpen(true); }}>Log out</KCButtonBase>
          {/* <KCButtonBase
            variant="outlined"
            kcvariant="outlined"
            sx={{ width: isMobile ? '100%': '400px' }}
            onClick={() => { setDeleteAccountDialogOpen(true); }}>Delete account</KCButtonBase> */}
        </Stack>
      </Box>
      <KCDialog
        customTitle="Logout"
        customContent="Are you sure you want to logout?"
        open={logoutDialog}
        onClose={() => setLogoutDialogOpen(false)}
        actions={
          <Stack
            direction="row"
            justifyContent="space-around"
            alignItems="center"
            spacing={2}
          >
            <KCButtonBase kcvariant='outlined'
              onClick={() => {
                logout();
                setLogoutDialogOpen(false);
              }}
              sx={{ width: '158px' }}
            >
              Yes
            </KCButtonBase>
            <KCButtonBase
              onClick={() => setLogoutDialogOpen(false)}
              sx={{ width: '158px' }}
            >
              No
            </KCButtonBase>
          </Stack>
        }
      />
      <KCDialog
        customTitle="Delete account"
        customContent="Are you sure you want to delete your account? This is permanent. Your reviews will still exist but you will no longer be able to delete them. "
        open={deleteAccountDialog}
        onClose={() => setDeleteAccountDialogOpen(false)}
        actions={
          <Stack
            direction="row"
            justifyContent="space-around"
            alignItems="center"
            spacing={2}
          >
            <KCButtonBase kcvariant='outlined'
              onClick={() => setDeleteAccountDialogOpen(false)}
              sx={{ width: '158px' }}
            >
              Cancel
            </KCButtonBase>
            <KCButtonBase
              onClick={() => {
                logout();
                setDeleteAccountDialogOpen(false);
              }}
              sx={{ width: '158px' }}
            >
              Delete account
            </KCButtonBase>
          </Stack>
        }
      />
    </>
  )
}

export default Account;