
import { Box, MenuItem, Stack, Typography } from '@mui/material';

import * as React from 'react';

import KCStarRating from '../../../../components/KCStarRating';
import KCSelect from '../../../../components/KCSelect';
import KCTextField from '../../../../components/KCTextField';
import { FormValues, NewReviewPageProps } from '../..';
import * as yup from 'yup';
import { useFormikContext } from 'formik';

export const FirstPageValidationSchema = yup.object().shape({
  overall_rating: yup.number().min(1).max(5).required('Overall Rating is required'),
  last_year_of_employment: yup.number().min(2000).required('Last Year of Employment is required'),
  review: yup.string().min(25).label("Review").required('Review is required'),
});

const FirstPage: React.FC<NewReviewPageProps> = (props) => {

  const currentYear = new Date().getFullYear();
  const lastYearOfEmploymentOptions = Array.from({ length: 11 }, (_, index) => currentYear - index);

  const formik = useFormikContext<FormValues>();

  return (
    <Box sx={{ padding: "40px" }}>
      <Stack spacing={4}>
        <Box>
          <Stack direction="row" spacing={2}>
            <Typography variant="body1" color="text.secondary" sx={{ paddingRight: '20px', fontWeight: "bold" }}>Overall Rating</Typography>
            <KCStarRating
              variant='dark'
              value={formik.values.overall_rating}
              onChange={(event, value) => formik.setFieldValue('overall_rating', value)}
            />
          </Stack>
          {formik.touched.overall_rating && formik.errors.overall_rating ? (
            <Typography variant="body2" color="error" sx={{ marginTop: '5px', fontSize: '0.75rem' }}>Overall Rating is required</Typography>
          ) : null}
        </Box>
        <Box>
          <Typography variant="body1" color="text.secondary" sx={{ fontWeight: "bold", marginBottom: "6px" }}>Last year of employment</Typography>
          <KCSelect
            variant='outlined'
            fullWidth
            id="last_year_of_employment"
            name="last_year_of_employment"
            // label="last_year_of_employment"
            value={formik.values.last_year_of_employment}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.last_year_of_employment && Boolean(formik.errors.last_year_of_employment)}
          >
            {lastYearOfEmploymentOptions.map((year) => (
              <MenuItem key={year} value={year}>{year}</MenuItem>
            ))}
          </KCSelect>
        </Box>
        <Box>
          <Typography variant="body1" color="text.secondary" sx={{ fontWeight: "bold", marginBottom: "6px" }}>Review</Typography>
          <Typography variant="body2" color="text.secondary" sx={{ marginBottom: "6px" }}>Tell us how you felt working here. Did you get paid well?
            Were your coworkers nice? Any info is good info.
            If you don't know where to start, try listing pros and cons.</Typography>
          <KCTextField
            variant='outlined'
            id="review"
            name="review"
            value={formik.values.review}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.review && Boolean(formik.errors.review)}
            helperText={formik.touched.review && formik.errors.review}
            multiline
            fullWidth
            rows={5}
            placeholder="Start typing here..."
          />
        </Box>
      </Stack>
    </Box>
  );
};



export default FirstPage;
