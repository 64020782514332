import { Box, Stack, Typography } from '@mui/material';
import React from 'react';
import KCSlider from '../../../../../../components/KCSlider';

interface CultureCardProps {
  title: string;
  description: string;
  value: number;
  marks: Array<{value: number, label: string}>;
}

const CultureCard: React.FC<CultureCardProps> = (props) => {  

  return (
   <Box  sx={{ backgroundColor: "#FFFFFF", padding: '32px', borderRadius: '18px'}} >
    <Stack>
      <Typography variant='h6' color='text.secondary'>
        {props.title}
      </Typography>
      <Typography variant='body2' color='text.secondary'>
        {props.description}
      </Typography>
      <KCSlider marks={props.marks} rainbow={true} defaultValue={props.value} step={1} min={1} max={5} disabled/>
    </Stack>
   </Box>
  );
};

export default CultureCard;
