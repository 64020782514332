import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface FilterState {
  name?: any;
  resetFilters?: any;
  submitSearch?: any;
}

const initialState: FilterState = {
  name: '',
  resetFilters: null,
  submitSearch: null
};

const slice = createSlice({
  name: 'filterState',
  initialState,
  reducers: {
    setFilterState: (state, action: PayloadAction<FilterState>) => {
      const { name, resetFilters, submitSearch } = action.payload;
      state.resetFilters = resetFilters !== undefined ? resetFilters : state.resetFilters;
      state.submitSearch = submitSearch !== undefined ? submitSearch : state.submitSearch;
      state.name = name !== undefined ? name : state.name;
    },
  },
});

export const { setFilterState } = slice.actions;

export default slice.reducer;

export const selectCurrentFilterState = (state: { filterState: FilterState }) => state.filterState;