
import { Box, Button, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import KCStarRating from '../../../../components/KCStarRating';
import IosShareIcon from '@mui/icons-material/IosShare';
import KCButtonBase from '../../../../components/Button';
import { useAuth0 } from "@auth0/auth0-react";
import { useParams } from 'react-router-dom';

const LockedReviewCard: React.FC = () => {

  const { loginWithRedirect } = useAuth0();

  const { establishmentId } = useParams<{ establishmentId: string }>();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const dateFormat = new Date();
  const dateOptions: Intl.DateTimeFormatOptions =  isMobile ? { year: 'numeric', month: 'short', day: 'numeric' }  : { year: 'numeric', month: 'long', day: 'numeric' };
  return (
   <Box 
    sx={{ 
      backgroundColor: "#FFFFFF", 
      padding: '20px', 
      borderRadius: '18px',
      position: 'relative',
    }}>
       
        <Stack direction="column" spacing={2}>
          <Box sx={{
            filter: 'blur(6px)',
            webkitFilter: 'blur(8px)',
          }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Stack direction="row" spacing={2} alignItems='center'>
                <KCStarRating value={4} variant='dark' readOnly />
                <Typography variant="body2" color="text.secondary">{4} stars</Typography>
              </Stack>
              <Typography variant="body2" color="text.secondary">{dateFormat.toLocaleDateString('en-US',dateOptions)}</Typography>
            </Box>
            <Typography variant="h5" color="text.secondary">
              Best place I've ever worked.
            </Typography>
            <Typography variant="body2" color="text.secondary">
            Nullam id dolor id nibh ultricies vehicula ut id elit. Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa
            <br/> Ornare vel eu leo. Nulla vitae elit libero, a pharetra augue. Curabitur blandit.
            </Typography>
            <Box>
              <Stack direction='row' spacing={6} sx={{ marginTop: '32px'}}>
                <Box>
                  <Typography variant="body1" color="text.secondary">Base Rate</Typography>
                  <Typography variant="h4"  color="text.secondary">$21.32<span style={{fontSize: '14px' }} >/hr</span></Typography>
                </Box>
                <Box>
                  <Typography variant="body1" color="text.secondary">Avg. tips</Typography>
                  <Typography variant="h4" color="text.secondary">$432</Typography>
                </Box>
              </Stack>
              <Stack 
                direction='row'
                justifyContent="space-between"
                alignItems="center"
              >
                <Button 
                  variant="text" 
                  sx={{
                    color: 'text.primary',
                    textTransform: 'none',
                    '&:hover': {
                      backgroundColor: 'transparent',
                      textDecoration: 'underline',
                      textDecorationColor: '#E44444',
                    },
                  }}
                >
                Hide ratings
                </Button>
                <Button 
                  variant="text" 
                  startIcon={<IosShareIcon />}
                  sx={{
                    color: 'text.secondary',
                    textTransform: 'none',
                    '&:hover': {
                      backgroundColor: 'transparent',
                      textDecoration: 'underline',
                      textDecorationColor: '#E44444',
                    },
                  }}
                  >
                    Share
                </Button>
              </Stack>
            </Box>
          </Box>
        </Stack>
        <Box sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        margin: 'auto', // Centers the button
        width: '310px', // Specified width of the button
        height: '40px', // An assumed height for your button
      }}>
        <KCButtonBase 
          sx={{  width: '310px'}}
          onClick={() => loginWithRedirect({
            appState: {
              returnTo: `${process.env.REACT_APP_OVERRIDE_PUBLIC_URL}/establishments/${establishmentId}`
          },
          authorizationParams: {
              screen_hint: "signup"
          }}
        )}
        >
          Login to read more
        </KCButtonBase>
      </Box>
   </Box>
  );
};

export default LockedReviewCard;
