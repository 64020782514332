
import { Box, InputAdornment, MenuItem, Stack, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import KCToggleGroup from '../../../../components/KCToggleGroup';
import KCSlider from '../../../../components/KCSlider';
import KCTextField from '../../../../components/KCTextField';
import KCSelect from '../../../../components/KCSelect';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { FormValues, NewReviewPageProps } from '../..';
import * as yup from 'yup';
import { useGetReviewRolesQuery } from '../../../../store/kcApi';
import { useFormikContext } from 'formik';

const filter = createFilterOptions<JobOptionType>();

interface JobOptionType {
  inputValue?: string;
  role: string;
}

export const SecondPageValidationSchema = yup.object().shape({
  role: yup.string().required('Role is required'),
  base_wage: yup.number().when('wage_type', {
    is: (val: string) => val === 'hourly',
    then: (schema) => schema.min(2.13).max(150).label('Base Wage').required('Base Wage is required'),
    otherwise: (schema) => schema.min(10000).max(200000).label('Base Wage').required('Base Wage is required')
  }),
  wage_type: yup.string().required('Wage Type is required'),
  avg_tips_per_shift: yup.number().min(0.00).max(5000).required('Average Tips per Shift is required'),
  avg_length_of_shift: yup.number().min(0.5).required('Average Length of Shift is required'),
  avg_shifts_per_week: yup.number().min(1).required('Average Shifts per Week is required'),
  avg_busyness: yup.number().required('Average Busyness is required'),
});


const SecondPage: React.FC<NewReviewPageProps> = (props) => {
  // Add your component logic here

  const [roleOptions, setRoleOptions] = React.useState<JobOptionType[]>([]);
  const formik = useFormikContext<FormValues>();

  const {
    data: jobRoles,
    isLoading,
    isFetching,
  } = useGetReviewRolesQuery();

  useEffect(() => {
    if (jobRoles?.data) {
      setRoleOptions(jobRoles.data.map((role) => {
        return { role: role.name } as JobOptionType;
      }))
    }
  }, [jobRoles, isLoading]);

  const marks = [{
    value: 1,
    label: 'Dead',
  },
  {
    value: 3,
    label: 'Average',
  },
  {
    value: 5,
    label: 'Busy',
  }]

  const lengthOfShifts = Array.from({ length: 20 }, (_, index) => index + 1);
  const numOfShifts = Array.from({ length: 14 }, (_, index) => index + 1);

  return (
    <Box sx={{ padding: "40px" }}>
      <Stack spacing={4}>
        <Box>
          <Typography variant="body1" color="text.secondary" sx={{ fontWeight: "bold", marginBottom: "6px" }}>Job Title</Typography>
          <Autocomplete
            value={formik.values.role}
            loading={isLoading || isFetching}
            onChange={(event, newValue) => {
              if (typeof newValue === 'string') {
                formik.setFieldValue('role', newValue);
              } else if (newValue && newValue.inputValue) {
                // Create a new value from the user input
                formik.setFieldValue('role', newValue.inputValue);
              } else {
                formik.setFieldValue('role', newValue?.role);
              }
            }}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);

              const { inputValue } = params;
              // Suggest the creation of a new value
              const isExisting = options.some((option) => inputValue === option.role);
              if (inputValue !== '' && !isExisting) {
                filtered.push({
                  inputValue,
                  role: `Add "${inputValue}"`,
                });
              }

              return filtered;
            }}
            selectOnFocus
            clearOnBlur
            fullWidth
            handleHomeEndKeys
            id="role"
            options={roleOptions}
            getOptionLabel={(option) => {
              // Value selected with enter, right from the input
              if (typeof option === 'string') {
                return option;
              }
              // Add "xxx" option created dynamically
              if (option.inputValue) {
                return option.inputValue;
              }
              // Regular option
              return option.role;
            }}
            renderOption={(props, option) => <li {...props}>{option.role}</li>}
            freeSolo
            renderInput={(params) => (
              <KCTextField
                variant='outlined'
                placeholder='Choose your role, or add new one'
                helperText={formik.touched.role && formik.errors.role}
                error={formik.touched.role && Boolean(formik.errors.role)}
                {...params} />
            )}
            sx={{
              '& .MuiAutocomplete-input': {
                padding: '4.5px 4px 7.5px 5px !important',
              },
              '& .MuiAutocomplete-option': {
                color: '#3E3938',
              }
            }}
          />
        </Box>
        <Box>
          <Typography variant="body1" color="text.secondary" sx={{ fontWeight: "bold", marginBottom: "6px" }}>Base Wage (pre-tip)</Typography>
          <Stack direction='row' spacing={2}>
            <KCTextField
              variant='outlined'
              placeholder='0.00'
              id='base_wage'
              name='base_wage'
              value={formik.values.base_wage}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.base_wage && Boolean(formik.errors.base_wage)}
              helperText={formik.touched.base_wage && formik.errors.base_wage}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
            />
            <KCSelect
              variant='outlined'
              sx={{ width: "208px" }}
              id='wage_type'
              name='wage_type'
              value={formik.values.wage_type}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            >
              <MenuItem value="hourly">Per Hour</MenuItem>
              <MenuItem value="annual">Per Year</MenuItem>
            </KCSelect>
          </Stack>
        </Box>
        <Box>
          <Stack direction='row' spacing={2}>
            <Stack direction='column' spacing={2}>
              <Typography variant="body1" color="text.secondary" sx={{ fontWeight: "bold", marginBottom: "6px" }}>Average tips per shift</Typography>
              <KCTextField
                variant='outlined'
                placeholder='0.00'
                id='avg_tips_per_shift'
                name='avg_tips_per_shift'
                value={formik.values.avg_tips_per_shift}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.avg_tips_per_shift && Boolean(formik.errors.avg_tips_per_shift)}
                helperText={formik.touched.avg_tips_per_shift && formik.errors.avg_tips_per_shift}
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
              />
            </Stack>
            <Stack direction='column' spacing={2}>
              <Typography variant="body1" color="text.secondary" sx={{ fontWeight: "bold", marginBottom: "6px" }}>Tip Pool?</Typography>
              <KCToggleGroup
                options={["Yes", "No"]}
                value={formik.values.tip_pool ? "Yes" : "No"}
                changeEvent={(value) => formik.setFieldValue('tip_pool', value === "Yes" ? true : false)}
              />
            </Stack>
          </Stack>
        </Box>
        <Box>
          <Stack direction='row' spacing={2}>
            <Stack direction='column' spacing={2}>
              <Typography variant="body1" color="text.secondary" sx={{ fontWeight: "bold", marginBottom: "6px" }}>Average shifts per week</Typography>
              <KCSelect
                variant='outlined'
                id='avg_shifts_per_week'
                name='avg_shifts_per_week'
                value={formik.values.avg_shifts_per_week}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.avg_shifts_per_week && Boolean(formik.errors.avg_shifts_per_week)}
              >
                <MenuItem value="" />
                {numOfShifts.map((shift) => (
                  <MenuItem key={shift} value={shift}>{shift} per week</MenuItem>
                ))}
              </KCSelect>
              {formik.errors.avg_shifts_per_week && <Typography variant='body2' sx={{ marginTop: '5px', fontSize: '0.75rem' }} color="error">{formik.errors.avg_shifts_per_week}</Typography>}
            </Stack>
            <Stack direction='column' spacing={2}>
              <Typography variant="body1" color="text.secondary" sx={{ fontWeight: "bold", marginBottom: "6px" }}>Average length of shift</Typography>
              <KCSelect
                variant='outlined'
                placeholder='0'
                id='avg_length_of_shift'
                name='avg_length_of_shift'
                value={formik.values.avg_length_of_shift}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.avg_length_of_shift && Boolean(formik.errors.avg_length_of_shift)}
              >
                <MenuItem value="" />
                {lengthOfShifts.map((length) => (
                  <MenuItem key={length} value={length}>{length > 1 ? length + " hours" : length + " hour"}</MenuItem>
                ))}
              </KCSelect>
              {formik.errors.avg_length_of_shift && <Typography variant='body2' sx={{ marginTop: '5px', fontSize: '0.75rem' }} color="error">{formik.errors.avg_length_of_shift}</Typography>}
            </Stack>
          </Stack>
        </Box>
        <Box>
          <Stack direction='column' spacing={2}>
            <Typography variant="body1" color="text.secondary" sx={{ fontWeight: "bold", marginBottom: "6px" }}>How busy is your usual shift?</Typography>
            <KCSlider
              rainbow={false}
              marks={marks}
              min={1}
              max={5}
              value={formik.values.avg_busyness}
              onChange={(event, value) => formik.setFieldValue('avg_busyness', value)}
            />
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
};

export default SecondPage;
