import { Box, styled, useMediaQuery, useTheme } from '@mui/material';
import GoogleMap from "google-maps-react-markers";
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import useSupercluster from 'use-supercluster';
import { ReactComponent as UserLocationIcon } from '../../../../assets/svg/icons/user_location.svg';
import { useAppSelector } from '../../../../hooks/hooks';
import { selectCurrentFilterState, setFilterState } from '../../../../store/filterSlice';
import { selectCurrentMapState, setMapState } from '../../../../store/mapSlice';
import { isInNYC, useUserLocation } from '../../../../utils/helpers';
import ClusterMarker from './ClusterMarker';
import Marker, { IPosition } from './Marker';

const Wrapper = styled(Box)({
  width: '100%',
  height: '100%'
})

interface GoogleMapProps {
  zoom?: number;
  mode?: string;
}

const mapStyles = [
  {
    featureType: "poi",
    elementType: "labels",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
];

interface UserMarkerProps {
  lat: number;
  lng: number;
}

const UserMarker: React.FC<UserMarkerProps> = ({ lat, lng }) => {
  return (
    <Box sx={{ position: 'absolute', transform: 'translate(-50%, -100%)' }}>
      <UserLocationIcon />
    </Box>
  );
};

const KCGoogleMap: React.FC<GoogleMapProps> = ({
  mode,
}) => {
  const [googleApiObj, setIsGoogleApiLoadedObj] = useState<any>(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const dispatch = useDispatch();
  const mapState = useAppSelector(selectCurrentMapState);
  const filterState = useAppSelector(selectCurrentFilterState);
  const { longitude, latitude } = useUserLocation();

  const newYorkDefault = useMemo(() => ({ lat: 40.714992, lng: -73.959613 }), []);
  const [defaultCenter, setDefaultCenter] = useState({ lat: 0, lng: 0 });
  const defaultZoom = 14;

  useEffect(() => {
    if (!googleApiObj?.map) {
      return;
    }
    const updateCenter = async () => {
      if ((latitude === 0 && longitude === 0) || !(await isInNYC({ latitude, longitude }))) {
        googleApiObj?.map.setCenter(newYorkDefault);
        setDefaultCenter(newYorkDefault);
      } else {
        googleApiObj?.map.setCenter({ lat: latitude, lng: longitude });
        setDefaultCenter({ lat: latitude, lng: longitude });
      }
    };

    updateCenter();
  }, [latitude, longitude, googleApiObj?.map, newYorkDefault]);

  const [mapBounds, setMapBounds] = useState({ bounds: [0, 0, 0, 0], zoom: 0 });
  const { lockBounds, activeHireIcon, visibleEstablishments } = mapState;
  const locations = useMemo(() => visibleEstablishments?.map((item: any): IPosition => {
    // setMapData
    return (
      {
        lat: item?.location?.coordinates?.[1] || 0,
        lng: item?.location?.coordinates?.[0] || 0,
        estId: item?.id || "",
        totalReviews: item?.total_reviews || 0,
        baseWage: item?.base_rate || 0,
        avgTips: item?.avg_tips || 0,
        sanitationScore: item?.health_score === "Not Yet Graded" ? "--" : item?.health_score || "--",
        type: item?.name || "",
        activeHireIcon: activeHireIcon || false,
        isHiring: item?.is_hiring || false,
      }
    );
  }) || [], [visibleEstablishments, activeHireIcon]);

  const mapRef = useRef();

  const points = locations.map((location: any, idx: any) => ({
    type: 'Feature',
    properties: {
      id: idx,
      cluster: false,
      estId: location.estId,
      baseWage: location.baseWage,
      avgTips: location.avgTips,
      totalReviews: location.totalReviews,
      sanitationScore: location.sanitationScore
    },
    geometry: { type: "Point", coordinates: [location.lng, location.lat] }
  })) || [];

  const { clusters } = useSupercluster({
    points: points,
    bounds: mapBounds.bounds,
    zoom: mapBounds.zoom,
    options: {
      radius: 75, maxZoom: 20,
    }
  });

  const getMapBoounds = useCallback((map: any, maps: any, places: any) => {
    const bounds = new maps.LatLngBounds();
    locations?.forEach(({ lat, lng }: { lat: any, lng: any }) => bounds.extend({ lat, lng }));
    return bounds;
  }, [locations]);

  const findCenter = useCallback((markers: { lat: number; lng: number; }[]) => {
    const lats = markers.map(m => m.lat);
    const lngs = markers.map(m => m.lng);
    return {
      lat: (Math.min(...lats) + Math.max(...lats)) / 2,
      lng: (Math.min(...lngs) + Math.max(...lngs)) / 2
    };
  }, []);

  const onLoaded = useCallback((map: any, maps: any, places: any) => {
    if (!map || !places?.length) return;

    const shouldCenterOnPlaces = lockBounds === true;
    const shouldResetCenter = !shouldCenterOnPlaces && filterState?.resetFilters;

    if (shouldCenterOnPlaces) {
      const center = findCenter(places);
      const bounds = getMapBoounds(map, maps, locations);
      map.fitBounds(bounds);
      map.setCenter(center);
      if (map.getZoom() > defaultZoom) map.setZoom(defaultZoom);
    } else if (shouldResetCenter) {
      map.setCenter(defaultCenter);
      map.setZoom(defaultZoom);
      dispatch(setFilterState({ resetFilters: false }));
    }

    map.addListener('mousedown', () => {
      dispatch(setMapState({ selectedMapMarkerEstId: null }));
    });
  }, [lockBounds, findCenter, getMapBoounds, dispatch, defaultCenter, filterState?.resetFilters, locations]);

  useEffect(() => {
    if (mapBounds.bounds[0] !== 0 || mapBounds.bounds[2] !== 0) {
      dispatch(setMapState({
        bounds: {
          northEastLatitude: mapBounds.bounds[3],
          northEastLongitude: mapBounds.bounds[2],
          southWestLatitude: mapBounds.bounds[1],
          southWestLongitude: mapBounds.bounds[0],
        }
      }))
    }
  }, [mapBounds, dispatch]);

  useEffect(() => {
    if (googleApiObj) {
      const { map, maps } = googleApiObj;
      if (map) {
        if (locations && locations?.length > 0) onLoaded(map, maps, locations);
      }
    }
  }, [googleApiObj, locations, lockBounds, dispatch, onLoaded])

  const handleMapChange = ({ bounds, zoom }: any) => {
    const ne = bounds.getNorthEast();
    const sw = bounds.getSouthWest();
    if (sw.lng() + ne.lng() > 1e-5 || sw.lat() + ne.lat() > 1e-5)
      setMapBounds({ ...mapBounds, bounds: [sw.lng(), sw.lat(), ne.lng(), ne.lat()], zoom });
  };

  return (
    <Wrapper>
      <GoogleMap
        apiKey={process.env.REACT_APP_GOOGLE_API_KEY || "AIzaSyCNVmTF4f5lgJSkhUDJY4q4PeDIl-d6mkY"}
        defaultZoom={defaultZoom}
        defaultCenter={defaultCenter}
        options={{
          styles: mapStyles,
          disableDefaultUI: true,
          zoomControl: true,
          clickableIcons: false,
          gestureHandling: 'greedy',
          controlSize: isMobile ? 20 : 40,
        }}
        onGoogleApiLoaded={({ map, maps }) => {
          setIsGoogleApiLoadedObj({ map, maps });
          mapRef.current = map;
        }}
        onChange={handleMapChange}
      >
        {clusters &&
          clusters.map((cluster) => {
            const [longitude, latitude] = cluster.geometry.coordinates;
            const { cluster: isCluster, point_count: pointCount } = cluster.properties;

            if (isCluster) {
              return (
                <ClusterMarker
                  key={`cluster-${cluster.id}`}
                  lat={latitude}
                  lng={longitude}
                >
                  {pointCount}
                </ClusterMarker>
              );
            } else {
              return (
                <Marker
                  activeHireIcon={activeHireIcon || false}
                  key={`marker-${cluster.properties.id}`}
                  id={cluster.properties.id}
                  lat={latitude}
                  lng={longitude}
                  estId={cluster.properties.estId}
                  baseWage={cluster.properties.baseWage}
                  avgTips={cluster.properties.avgTips}
                  totalReviews={cluster.properties.totalReviews}
                  sanitationScore={cluster.properties.sanitationScore}
                  type={mode}
                />
              )
            }
          })}
        <UserMarker lat={latitude} lng={longitude} />
      </GoogleMap>
    </Wrapper>
  );
};

export default KCGoogleMap;