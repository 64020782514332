import React from 'react';
import { Dialog, DialogContent, DialogProps, DialogTitle, styled } from '@mui/material';

interface KCDialogProps extends DialogProps {
  // Add any additional props you need for your custom dialog
  // For example, you can add a custom title or content
  customTitle: string;
  customContent: string;
  actions?: React.ReactNode;
}

const StyledDialog = styled(Dialog)<DialogProps>(({ theme }) => ({
  
  '& .MuiDialog-paper': {
    borderRadius: '32px',
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    padding: '24px',
    width: '120vw',
  },
}));

const KCDialog: React.FC<KCDialogProps> = ({
  customTitle,
  customContent,
  ...dialogProps
}) => {

  const dangerouslySetInnerHTMLContent = {
    __html: customContent,
  };

  return (
    <StyledDialog {...dialogProps}>
      <DialogTitle 
        sx={{
          textAlign: 'center',
          color: '#3E3938',
        }}
      >
        {customTitle}
      </DialogTitle>
      <DialogContent
        sx={{
          textAlign: 'center',
          color: '#3E3938',
          mb: '24px',
        }}
      >
        <div dangerouslySetInnerHTML={dangerouslySetInnerHTMLContent} />
      </DialogContent>
      {dialogProps.actions}
    </StyledDialog>
  );
};

export default KCDialog;