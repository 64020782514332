import React from 'react';
import GoogleMapReact from 'google-map-react';
import { Box, Skeleton, SxProps, Theme, Typography } from '@mui/material';
import { Room } from '@mui/icons-material';

interface MiniLocationProps {
  lat: number;
  lng: number;
}

interface MarkerProps {
  lat: number; // Latitude for the marker
  lng: number; // Longitude for the marker
  sx?: SxProps<Theme>; // Optional style overrides
}

const Marker: React.FC<MarkerProps> = ({ lat, lng, sx }) => {
  return (
    <div style={{ position: 'absolute', transform: 'translate(-50%, -100%)' }}>
      <Room sx={{ color: 'red', ...sx }} />
    </div>
  );
};

const MiniLocation: React.FC<MiniLocationProps> = ({ lat, lng }) => {
  return (
    <Box sx={{
      padding: '0px 0px 0px 0px',
    }}>
      <Typography 
        variant='subtitle2' 
        sx={{
          textTransform: 'uppercase',
          color: '#837A78',
          fontSize: '20px',
          padding: '20px 0px 20px 0px',
        }}
      >
        Location
      </Typography>
      <Box sx={{
        height: '200px',
        width: '100%',
      }}>
        { (lat === 0 && lng === 0) ? (

          <Skeleton
            variant="rectangular"
            sx={{
              height: '200px',
              width: '100%',
            }}
          />
        ) : (
        <GoogleMapReact
          bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '' }}
          defaultCenter={{ lat, lng }}
          defaultZoom={15}
          options={{
            gestureHandling: 'none',
            disableDefaultUI: true,
          }}
        >
          <Marker
            lat={lat}
            lng={lng}
            sx={{ fontSize: '2rem' }}
          />
        </GoogleMapReact>
        )}
      </Box>
    </Box>
  );
};

export default MiniLocation;
