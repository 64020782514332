import { Box, Stack, Typography } from "@mui/material";
import { formatDistance } from "date-fns";
import { JobListing } from "../../../../store/kcApi";
import { getPositionWageInfo } from "../../../../utils/helpers";

export interface PositionCardProp {
  position: JobListing;
  handleClick?: () => void;
}

export function truncateWithEllipsis(str: string) {
  const words = str.split(' ');
  return words.length > 3 ? words.slice(0, 3).join(' ') + ' ...' : str;
}

const PositionCard: React.FC<PositionCardProp> = ({
  position,
  handleClick,
}) => {
  return (
    <Box
      padding={3}
      bgcolor="#FFFFFF"
      borderRadius="18px"
      width={260}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      gap={2}
      sx={{
        cursor: 'pointer',
        '&:hover': {
          boxShadow: '0px 2px 15px 0px rgba(0, 0, 0, 0.1)',
        },
        '&:active': {
          background: 'rgba(251, 251, 250, 1)',
        }
      }}
      onClick={handleClick}
    >
      <Stack width={{ xs: '210px', md: '100%' }}>
        <Typography variant="h6" fontWeight={600} lineHeight='100%'>{truncateWithEllipsis(position.role || "")}</Typography>
        <Stack mt={2}>
          <Typography variant="body1">{position.roleType?.split('_').join(' ')} • {position.wageType}</Typography>
          <Typography variant="body1" fontWeight={600}>{getPositionWageInfo(position)}</Typography>
          <Typography variant="body1">Experience: {position.yearsOfExperience}</Typography>
        </Stack>
      </Stack>
      <Typography variant="body2" color="text.secondary">Posted {formatDistance(position.createdAt || "", new Date(), { addSuffix: true })}</Typography>
    </Box>
  )
}

export default PositionCard;