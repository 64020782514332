import { createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { JobListing } from './kcApi';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

interface SavedJobsState {
  jobList: JobListing[],
  savedJobsError?: FetchBaseQueryError | SerializedError,
  loadingSavedJobs?: boolean
}

const initialState: SavedJobsState = {
  jobList: []
};

const slice = createSlice({
  name: 'savedJobs',
  initialState,
  reducers: {
    setSavedJobs: (state, action: PayloadAction<SavedJobsState>) => {
      const { jobList, savedJobsError, loadingSavedJobs } = action.payload;
      state.jobList = jobList;
      state.savedJobsError = savedJobsError;
      state.loadingSavedJobs = loadingSavedJobs;
    },
    addNewSavedJob: (state, action: PayloadAction<JobListing>) => {
      state.jobList = [...state.jobList, action.payload];
    },
    deleteSavedJob: (state, action: PayloadAction<JobListing>) => {
      state.jobList = state.jobList.filter(job => job.id !== action.payload.id);
    },
  },
});

export const { setSavedJobs, addNewSavedJob, deleteSavedJob } = slice.actions;

export default slice.reducer;
