import { Box, Container, Tab, Tabs, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useState } from "react";
import Account from "./components/Accout";
import PersonalDetails from "./components/PersonalDetails";
import Reviews from "./components/Reviews";
import SavedJobs from "./components/SavedJobs";
const ResponsiveContainer = ({ children }: { children: React.ReactNode }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  if (isMobile) {
    return (
      <Box>
        {children}
      </Box>
    );
  }

  return (
    <Container>
      {children}
    </Container>
  );
};

const a11yProps = (index: number) => {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
    sx: { alignItems: 'flex-start' },
  };
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      sx={{ flexBasis: '75%' }}
      {...other}
    >
      {value === index && children}
    </Box>
  );
}

const ProfilePage: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{
      backgroundColor: '#FBF8F5',
      paddingBottom: '75px'
    }}>
      <ResponsiveContainer>
        <Typography variant={isMobile ? 'h5' : 'h1'} py={!isMobile ? 8 : 2} textAlign='center'>Profile</Typography>
        <Box
          sx={{
            bgcolor: 'background.paper',
            display: 'flex',
            minHeight: '570px',
            borderRadius: '30px',
            [theme.breakpoints.down('sm')]: {
              flexDirection: 'column'
            }
          }}
        >
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            sx={{ 
              borderRight: 1, 
              borderColor: 'divider', 
              flexBasis: '25%', 
              padding: isMobile ? '16px' : '32px',
              textAlign: 'left',
              [theme.breakpoints.down('sm')]: {
                borderBottom: 1,
                borderColor: 'divider',
              }
            }}
          >
            <Tab label="PERSONAL DETAILS" {...a11yProps(0)} />
            <Tab label="Reviews" {...a11yProps(1)} />
            <Tab label="Saved Jobs" {...a11yProps(2)} />
            <Tab label="Account" {...a11yProps(3)} />
          </Tabs>
          <TabPanel value={value} index={0}><PersonalDetails /></TabPanel>
          <TabPanel value={value} index={1}><Reviews /></TabPanel>
          <TabPanel value={value} index={2}><SavedJobs /></TabPanel>
          <TabPanel value={value} index={3}><Account /></TabPanel>
        </Box>
      </ResponsiveContainer>
    </Box>
  )
}

export default ProfilePage;