export const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export enum ExplorePageEvents {
}

export enum NewReviewEvents {
  NewReviewStarted = "New Review Started",
  NewReviewSubmitted = "New Review Submitted",
  NewReviewSubmittedError = "New Review Submitted Error",
  NewReviewDuplicate = "New Review Duplicate",
  NewReviewUpdated = "New Review Updated",
  NewReviewUpdatedFailed = "New Review Updated Failed",
  NewReviewFormPageChanged = "New Review Form Page Changed",
  NewReviewFormPageError = "New Review Form Page Error",
  NewReviewFormPageValidation = "New Review Form Page Validation Error",
}

export enum WriteReviewPageEvents {
  WriteReviewPageSearch = "Write Review Page Search",
  WriteReviewPageSearchError = "Write Review Page Search Error",
  WriteReviewPageSearchSuccess = "Write Review Page Search Success",
  WriteReviewPageSearchExpand = "Write Review Page Search Expand",
}