import { Box, Grid, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import KCStarRating from '../../../../components/KCStarRating';
import KCButtonBase from '../../../../components/Button';
import { East } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { setMapState } from '../../../../store/mapSlice';
import { ReactComponent as HireIcon } from '../../../../assets/svg/icons/hire_icon_white.svg';

export interface EstablishmentFilterCardProps {
  image: string;
  starRating: number;
  numOfReviews: number;
  name: string;
  address: string;
  baseRate: number;
  avgTips: number;
  sanitationScore: string;
  distance?: number;
  estId: string;
  isHiring: boolean;
  focused?: boolean
}

const EstablishmentFilterCard: React.FC<EstablishmentFilterCardProps> = ({
  image,
  starRating,
  name,
  address,
  baseRate,
  avgTips,
  sanitationScore,
  distance,
  numOfReviews,
  estId,
  isHiring,
  focused
}) => {

  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      borderRadius={4}
      sx={{
        padding: '16px',
        backgroundColor: focused ? 'rgba(228, 224, 223, 0.15)' : '#ffffff',
        borderBottom: '1px solid #E4E0DF',
        '&:hover': {
          backgroundColor: 'rgba(228, 224, 223, 0.15)',
        },
      }}
      onClick={() => {
        dispatch(setMapState({ animateMarkerId: estId }))
      }}
    >
      <Stack spacing={2}>
        <Grid container>
          <Grid item xs={4} md={4}>
            <Box sx={{
              backgroundImage: `url(${image})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              height: {
                sm: '143px',
                xs: '100px'
              },
              width: '100%',
              borderRadius: '16px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: {
                sm: 'flex-end',
                xs: 'flex-start'
              }
            }}>
            </Box>
          </Grid>
          <Grid item xs={8} md={8}>
            <Stack sx={{ marginLeft: '20px' }} spacing={1}>
              <Stack direction={'row'} spacing={2} alignItems="center">
                {isHiring && <Box
                  bgcolor='#9747FF'
                  borderRadius='8px'
                  padding='8px 12px'
                  display='flex'
                  alignItems='center'
                  gap='8px'
                >
                  <HireIcon width={16} height={16} />
                  <Typography variant='body1' color="common.white" textTransform='uppercase' fontWeight={600} display={{ xs: 'none', md: 'block' }}>Hiring</Typography>
                </Box>}
                <KCStarRating readOnly value={starRating} variant='dark' />
                <Typography variant="body2" color="text.secondary.main">{starRating.toFixed(1)}</Typography>
              </Stack>
              <Typography variant="h6" color="text.secondary" fontSize={20}>{name}</Typography>
              <Typography variant="body2" color="text.secondary" fontSize={12}>{address}</Typography>
            </Stack>
          </Grid>
        </Grid>
        <Stack direction="row" justifyContent={{ xs: "space-between", md: 'flex-start' }} spacing={{ md: 6 }} sx={{ marginTop: '32px' }}>
          <Box>
            <Typography variant="body1" color="text.secondary">Base Rate</Typography>
            <Typography variant="h6" color="text.secondary" fontSize={20}>${baseRate === 0 ? "--" : baseRate.toFixed(0)}{baseRate !== 0 && (<span style={{ fontSize: '14px' }} >/hr</span>)}</Typography>
          </Box>
          <Box>
            <Typography variant="body1" color="text.secondary">Avg. tips</Typography>
            <Typography variant="h6" color="text.secondary" fontSize={20}>${avgTips === 0 ? "--" : avgTips.toFixed(0)}</Typography>
          </Box>
          <Box>
            <Typography variant="body1" color="text.secondary">Sanitation {!isMobile ? `Score` : ''}</Typography>
            <Typography variant="h6" color="text.secondary" fontSize={20}>{sanitationScore}</Typography>
          </Box>
        </Stack>
        <KCButtonBase
          fullWidth
          kcvariant='outlined'
          onClick={() => {
            navigate(`${process.env.REACT_APP_OVERRIDE_PUBLIC_URL}/establishments/${estId}`)
          }}
        >
          View Establishment&nbsp;&nbsp;<East />
        </KCButtonBase>
      </Stack>
    </Box>
  );
};

export default EstablishmentFilterCard;
