import { Box, Container, Stack, Typography } from '@mui/material';
import React from 'react';

import donutRed from "../../assets/images/kc-donut-red.png";
import KCButtonBase from '../../components/Button';

const NotFound: React.FC = () => {
  return (
    <Box
    sx={{
      backgroundColor: '#FBF8F5',
      paddingBottom: '40px'
    }}
    >
      <Container>
        <Stack spacing={2} justifyContent="center" alignItems="center">
          <Typography variant="h2" sx={{
            padding: "20px",
          }}>404</Typography>
          <Typography variant="body1">Page Not Found</Typography>
          <Box
            component="img"
            alt="The house from the offer."
            src={donutRed}
            className="establishment-img"
            padding="60px"
          />
          <KCButtonBase
            sx={{ width: '310px' }}
            onClick={() => window.location.href = `${process.env.REACT_APP_OVERRIDE_PUBLIC_URL}/`}
          >
            Back to Home
          </KCButtonBase>
        </Stack>
      </Container>
    </Box>
  );
};

export default NotFound;