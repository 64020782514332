import { Rating, RatingProps, styled } from '@mui/material';
import React from 'react';

import { ReactComponent as EmptyStar } from '../../assets/svg/Star/star_fill_empty.svg';
import { ReactComponent as EmptyStarDark } from '../../assets/svg/Star/star_fill_empty_dark.svg';
import { ReactComponent as FilledStar } from '../../assets/svg/Star/star_fill_full.svg';
import { ReactComponent as FilledStarDark } from '../../assets/svg/Star/star_fill_full_dark.svg';

import './style.css';

const KCStarStyled = styled(Rating)(({ theme }) => ({
}));


const KCStarRating: React.FC<RatingProps & { variant: string }> = (props) => {
  const { variant, ...otherProps } = props;

  return (
    <KCStarStyled
      {...otherProps}
      getLabelText={(value: number) => `${value} Star${value !== 1 ? 's' : ''}`}
      // precision={0.5}
      icon={variant === "dark" ? <FilledStarDark style={{ width: '100%', height: '100%' }} /> : <FilledStar className='custom-icon' />}
      emptyIcon={variant === "dark" ? <EmptyStarDark style={{ width: '100%', height: '100%' }} /> : <EmptyStar className='custom-icon' />}

    // Apply custom and passed styles
    />
  );
}

export default KCStarRating;