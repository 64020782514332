import React from 'react';
import { Stack } from '@mui/material';
import EstablishmentSearchCard, { EstablishmentSearchCardProps } from '../EstablishmentSearchCard';

interface SearchCardsProps {
  data: EstablishmentSearchCardProps[];
}

const SearchCards: React.FC<SearchCardsProps> = ({ data }) => {
  return (
    <Stack spacing={4}>
      {data.map((item) => (
        <EstablishmentSearchCard 
          key={item.gid !== "" ? item.gid : item.estId} 
          name={item.name} 
          address={item.address} 
          baseRate={item.baseRate}
          avgTips={item.avgTips}
          distance={item.distance}
          sanitationScore={item.sanitationScore}
          image={item.image}
          starRating={item.starRating}
          numOfReviews={item.numOfReviews}
          estId={item.estId}
          gid={item.gid}
        />
      ))}
    </Stack>
  );
};

export default SearchCards;
