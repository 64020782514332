import { Box, Stack, Typography } from "@mui/material";
import establishmentBkg from "../../../../assets/placeholders/establishment.jpg";
import { ReactComponent as ApplyIcon } from "../../../../assets/svg/icons/appy_icon.svg";
import { ReactComponent as SaveIcon } from "../../../../assets/svg/icons/save_icon.svg";
import KCButtonBase from "../../../../components/Button";
import { JobListing } from "../../../../store/kcApi";
import { getPositionWageInfo } from "../../../../utils/helpers";
interface JobListingDetailProps {
  position: JobListing,
  isSaved: boolean,
  handleSave: (position: JobListing, isSaved: boolean) => void,
}

const JobListingDetail: React.FC<JobListingDetailProps> = ({ position, isSaved, handleSave }) => {
  return (
    <Box padding={{ xs: '16px', sm: '48px' }} minWidth={{ sm: '562px' }}>
      {/* <IconButton sx={{ position: 'absolute', right: '20px', top: '48px' }} onClick={() => { setopenDrawer(false); }}>
        <Close width={16} height={16} />
      </IconButton> */}
      <Stack direction="row" gap='40px'>
        <Box
          component="img"
          sx={{
            height: { xs: '108px', sm: '135px' },
            width: { xs: '108px', sm: '135px' },
            borderRadius: "20px",
          }}
          alt="The house from the offer."
          src={establishmentBkg}
          className="establishment-img"
        />
        <Stack>
          <Typography variant="h6" fontWeight={600} color="text.secondary" maxWidth={600}>{position.role}</Typography>
          <Typography variant="body1" color="text.secondary">{position.roleType}</Typography>
          <Stack mt={2}>
            <Typography variant="body1" color="text.secondary">{position.roleType?.split('_').join(' ')} • {position.wageType}</Typography>
            <Typography variant="body1" color="text.secondary" fontWeight={600}>{getPositionWageInfo(position)}</Typography>
            <Typography variant="body1" color="text.secondary">Experience: {position?.yearsOfExperience}</Typography>
          </Stack>
        </Stack>
      </Stack>
      <Stack mt={4} gap={3} direction={{ xs: 'column', sm: 'row' }}>
        <KCButtonBase sx={{ width: { xs: '100%', sm: '260px' } }}
          onClick={() => {
            window.location.href = position.listingLink || "";
          }}
        >Apply&nbsp;<ApplyIcon /></KCButtonBase>
        <KCButtonBase kcvariant="outlined" sx={{ width: { xs: '100%', sm: '260px' } }} onClick={() => handleSave(position, isSaved)}>{isSaved ? 'Unsave' : 'Save'}&nbsp;<SaveIcon /></KCButtonBase>
      </Stack>
      <Stack mt={4}>
        <Typography variant="body1" color="text.secondary" width={1} style={{ whiteSpace: 'pre-wrap' }}>{position.listingDetails}</Typography>
      </Stack>
    </Box>
  );
}

export default JobListingDetail;