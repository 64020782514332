import { Box, Typography } from "@mui/material";
import React from "react";

export interface IPosition {
  key: string;
  lat: number;
  lng: number;
  children?: React.ReactNode;
}

const ClusterMarker: React.FC<IPosition> = ({ children, ...props }) => {
  return (
    <Box
      width={26}
      height={26}
      borderRadius="100px"
      bgcolor="#FFFFFF"
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{
        filter: 'drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25))',
        transform: "translate(-50%, -50%)"
      }}
    >
      <Typography variant="caption" fontWeight={600} fontSize={12}>{children}</Typography>
    </Box>
  );
};

export default ClusterMarker;