import React, { useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Box } from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

interface FAQProps {
  faqData: { question: string; answer: string }[];
}

const FrequentlyAskedQuestions: React.FC<FAQProps> = ({ faqData }) => {

   // State to track the expanded accordion panel
   const [expanded, setExpanded] = useState<string | false>(false);

   const handleChange =
     (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
       setExpanded(isExpanded ? panel : false);
     };
 

  return (
    <div>
      {faqData.map((faq, index) => (
    <Box sx={{
      backgroundColor: "#FBF8F5",
      padding: '20px',
      marginBottom: '50px',
      borderRadius: '12px',
    }}>
        <Accordion 
          key={index}
          expanded={expanded === `panel${index}`}
          onChange={handleChange(`panel${index}`)}
          sx={{
            backgroundColor: "transparent",
            boxShadow: "none",
          }}
        >
          <AccordionSummary 
            expandIcon={expanded === `panel${index}` ? <RemoveIcon /> : <AddIcon />}
            id={`panel${index}bh-header`}
          >
            <Typography variant='h4' color="text.secondary">{faq.question}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant='body1' color="text.secondary">{faq.answer}</Typography>
          </AccordionDetails>
        </Accordion>
        </Box>
      ))}
    </div>
  );
};

export default FrequentlyAskedQuestions;
