import { Box, IconButton, Skeleton, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import PositionCard, { truncateWithEllipsis } from "./PositionCard";
import PositionDrawer from "./PositionDrawer";

import { useAuth0 } from "@auth0/auth0-react";
import { Favorite, FavoriteBorder, NavigateBefore } from "@mui/icons-material";
import { formatDistance } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import LoginModal from "../../../../components/KCModal/LoginModal";
import { JobListing, useDeleteUserSavedJobListingsMutation, useGetPublicEstablishmentJobListingsQuery, usePostUserSavedJobListingsMutation } from "../../../../store/kcApi";
import { addNewSavedJob, deleteSavedJob } from "../../../../store/savedJobsSlice";
import { RootState } from "../../../../store/store";
import { getPositionWageInfo } from "../../../../utils/helpers";
import JobListingDetail from "./JobListingDetail";

interface OpenPositionsProps {
  isLoading: boolean;
  estName?: string;
  estId: string;
}

const OpenPositions: React.FC<OpenPositionsProps> = (props) => {
  const theme = useTheme();
  const [openDrawer, setopenDrawer] = useState(false);
  const [openLoginDrawer, setOpenLoginDrawer] = useState(false);
  const [positionListDrawer, setPositionListDrawer] = useState(false);
  const [selectedPosition, setSelectedPosition] = useState(0);
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { isAuthenticated, isLoading: authLoading } = useAuth0();
  const currentUser = useSelector((state: RootState) => state.auth);
  const [userAuthReady, setUserAuthReady] = useState<boolean>(false);
  const savedJobList = useSelector((state: RootState) => state.savedJobs.jobList);
  const [deleteUserSavedJobListings] = useDeleteUserSavedJobListingsMutation();
  const [postUserSavedJobListings] = usePostUserSavedJobListingsMutation();
  const {
    data: rawJobListings,
    error: jobListingsError,
    isLoading: loadingJobListings
  } = useGetPublicEstablishmentJobListingsQuery({ establishmentId: props.estId }, { skip: !props.estId })

  const [positionData, setPositionData] = useState<JobListing[]>([]);
  // console.log("est Id = ", props.estId, " joblistings = ", rawJobListings);

  useEffect(() => {
    if (!authLoading && currentUser.token != null) {
      setUserAuthReady(true);
    }
  }, [authLoading, currentUser]);

  useEffect(() => {
    if (rawJobListings?.data)
      setPositionData(rawJobListings?.data?.map((joblisting: any) => ({
        id: joblisting.id,
        listingDetails: joblisting.listingDetails,
        listingLink: joblisting.listingLink,
        createdAt: joblisting.createdAt,
        role: joblisting.role,
        roleType: joblisting.roleType,
        wageType: joblisting.wageType,
        wageValue: joblisting.wageValue,
        minWageValue: joblisting.minWageValue,
        maxWageValue: joblisting.maxWageValue,
        experience: joblisting.yearsOfExperience,
      })))
  }, [rawJobListings, savedJobList])
  const dispatch = useDispatch();
  const handleSave = (position: JobListing, isSaved: boolean) => {
    if (isSaved) {
      deleteUserSavedJobListings({ userId: currentUser.user_id, saveJobListing: { job_listing_id: String(position.id) } })
        .unwrap()
        .then(payload => dispatch(deleteSavedJob(position)))
        .catch(err => console.error('deleteUserSavedJobListings', err));
    } else {
      postUserSavedJobListings({ userId: currentUser.user_id, saveJobListing: { job_listing_id: String(position.id) } })
        .unwrap()
        .then(payload => dispatch(addNewSavedJob(position)))
        .catch(err => console.error('postUserSavedJobListings', err));
    }
  }
  if (props.isLoading || loadingJobListings) {
    return (
      <Stack spacing={4} direction="row">
        <Skeleton variant="rounded" width={256} height={270} />
        <Skeleton variant="rounded" width={256} height={270} />
        <Skeleton variant="rounded" width={256} height={270} />
      </Stack>
    );
  } else if (jobListingsError !== undefined) {
    return (
      <Box>
        <Typography variant='h5' sx={{ color: 'red' }}>
          Error
        </Typography>
      </Box>
    );
  }

  if (!rawJobListings?.data || rawJobListings?.data.length === 0) {
    return (
      <Box>
        <Typography
          variant='subtitle2'
          sx={{
            textTransform: 'uppercase',
            color: '#837A78',
            fontSize: '20px',
            padding: '0px 0px 20px 0px',
          }}
        >Open Positions</Typography>
        <Box
          sx={{
            backgroundColor: '#FFFFFF',
            padding: '40px',
            borderRadius: '18px'
          }}
        >
          <Typography variant='body1' color='text.secondary' textAlign='center'>
            No positions for {props.estName} yet.
          </Typography>
        </Box>
      </Box>
    )
  }

  return (
    <Box>
      <Typography
        variant='subtitle2'
        sx={{
          textTransform: 'uppercase',
          color: '#837A78',
          fontSize: '20px',
          padding: '0px 0px 20px 0px',
        }
        }
      >Open Positions</Typography>
      <Stack direction="row" spacing={3} sx={{ overflowX: { xs: 'scroll', md: 'unset' } }}>
        {positionData && positionData.slice(0, 3).map((position: JobListing, idx) => (
          <PositionCard
            position={position}
            handleClick={() => { setSelectedPosition(idx); setopenDrawer(true); }}
            key={idx}
          />
        ))}
      </Stack>
      <Typography variant="body1" color="text.primary" fontWeight={600} mt={3} sx={{ textDecoration: "underline", cursor: 'pointer' }}
        onClick={() => {
          (isAuthenticated && !authLoading && userAuthReady) ? (
            isMobile ? setPositionListDrawer(true) : setopenDrawer(true)
          ) : (
            setOpenLoginDrawer(true)
          )
        }}
      >More open positions</Typography>
      <LoginModal
        open={openLoginDrawer}
        onClose={() => setOpenLoginDrawer(false)}
        returnTo={`${process.env.REACT_APP_OVERRIDE_PUBLIC_URL}/establishments/${props.estId}`}
      />
      {positionData && !isMobile ? (
        <PositionDrawer
          anchor="right"
          open={openDrawer}
          onClose={() => setopenDrawer(false)}
        >
          <Box width='70vw' display='flex'>
            <Box
              sx={{
                minWidth: '400px',
                borderRight: '1px solid',
                borderRightColor: 'divider',
              }}
            >
              <Stack>
                {positionData.map((position, idx) => {
                  const isSaved = savedJobList.some(job => job.id === position.id);
                  return (
                    <Box
                      key={idx}
                      sx={{
                        minWidth: '180px',
                        padding: '32px',
                        cursor: 'pointer',
                        borderBottom: '1px solid',
                        borderBottomColor: 'divider',
                        borderLeft: idx === selectedPosition ? '7px solid' : 'unset',
                        '&:hover': {
                          background: 'rgba(251, 251, 250, 1)'
                        },
                      }}
                      onClick={() => { setSelectedPosition(idx); }}
                    >
                      <Stack>
                        <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
                          <Stack>
                            <Typography variant="body2" color="text.secondary">{formatDistance(position.createdAt || "", new Date(), { addSuffix: true })}</Typography>
                            <Typography variant="h6" fontWeight={600} color="text.secondary">{truncateWithEllipsis(position.role || "")}</Typography>
                            <Typography variant="body1" color="text.secondary">{props.estName}</Typography>
                          </Stack>
                          <IconButton onClick={(event) => {
                            event.stopPropagation();
                            handleSave(position, isSaved);
                          }}>
                            {isSaved ? <Favorite color="primary" /> : <FavoriteBorder color="primary" />}
                          </IconButton>
                        </Stack>
                        <Stack mt={2}>
                          <Typography variant="body1" color="text.secondary">{position.roleType?.split('_').join(' ')} • {position.wageType}</Typography>
                          <Typography variant="body1" color="text.secondary" fontWeight={600}>{getPositionWageInfo(position)}</Typography>
                          <Typography variant="body1" color="text.secondary">Experience: {position.yearsOfExperience}</Typography>
                        </Stack>
                      </Stack>
                    </Box>
                  );
                })}
              </Stack>
            </Box>
            {selectedPosition >= 0 && selectedPosition < positionData.length && <JobListingDetail position={positionData[selectedPosition]} handleSave={handleSave} isSaved={savedJobList.some(job => job.id === positionData[selectedPosition].id)} />}
          </Box>
        </PositionDrawer>
      ) : (
        <>
          <PositionDrawer
            anchor="bottom"
            open={positionListDrawer}
            onClose={() => setPositionListDrawer(false)}
            PaperProps={{
              sx: {
                borderRadius: '32px 32px 0 0',
                top: '70px'
              }
            }}
          >
            <Box bgcolor='#F4F3F2' pt="51px" pb="16px" px="16px" display="flex" alignItems="center">
              <Box
                display="flex"
                mr="50px"
                onClick={() => setPositionListDrawer(false)}
              >
                <NavigateBefore />
                <Typography variant="body1" color="primary">Back</Typography>
              </Box>
              <Typography
                variant="body1"
                color="text.secondary"
                fontWeight={600}
              >Open Positions</Typography>
            </Box>
            <Stack>
              {positionData.map((position, idx) => {
                const isSaved = savedJobList.some(job => job.id === position.id);
                return (
                  <Box
                    key={idx}
                    sx={{
                      minWidth: '180px',
                      padding: '16px',
                      borderBottom: '1px solid',
                      borderBottomColor: 'divider',
                      '&:hover': {
                        background: 'rgba(251, 251, 250, 1)'
                      },
                    }}
                    onClick={() => { setSelectedPosition(idx); setopenDrawer(true); setPositionListDrawer(false); }}
                  >
                    <Stack>
                      <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
                        <Stack>
                          <Typography variant="body2" color="text.secondary">1 week ago</Typography>
                          <Typography variant="h6" fontWeight={600} color="text.secondary">{position.role}</Typography>
                          <Typography variant="body1" color="text.secondary">{position.roleType}</Typography>
                        </Stack>
                        <IconButton onClick={(event) => {
                          event.stopPropagation();
                          handleSave(position, isSaved);
                        }}>
                          {isSaved ? <Favorite color="primary" /> : <FavoriteBorder color="primary" />}
                        </IconButton>
                      </Stack>
                      <Stack mt={2}>
                        <Typography variant="body1" color="text.secondary">{positionData[selectedPosition].roleType?.split('_').join(' ')} • {positionData[selectedPosition].wageType}</Typography>
                        <Typography variant="body1" color="text.secondary" fontWeight={600}>{getPositionWageInfo(positionData[selectedPosition])}</Typography>
                        <Typography variant="body1" color="text.secondary">Experience: {positionData[selectedPosition].yearsOfExperience}</Typography>
                      </Stack>
                    </Stack>
                  </Box>
                );
              })}
            </Stack>
          </PositionDrawer>
          <PositionDrawer
            anchor="bottom"
            open={openDrawer}
            onClose={() => setopenDrawer(false)}
            PaperProps={{
              sx: {
                borderRadius: '32px 32px 0 0',
                top: '70px'
              }
            }}
          >
            <Box>
              <Box bgcolor='#F4F3F2' pt="51px" pb="16px" px="16px" display="flex" gap={1}
                onClick={() => { setopenDrawer(false); setPositionListDrawer(true); }}
              >
                <NavigateBefore />
                <Typography variant="body1" color="primary">All openings</Typography>
              </Box>
              {selectedPosition >= 0 && selectedPosition < positionData.length && <JobListingDetail position={positionData[selectedPosition]} handleSave={handleSave} isSaved={savedJobList.some(job => job.id === positionData[selectedPosition].id)} />}
            </Box>
          </PositionDrawer>
        </>)
      }
    </Box>
  )
}

export default OpenPositions;