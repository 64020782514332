
import { Select, SelectProps, styled } from '@mui/material';
import React from 'react';


interface KCSelectProps extends SelectProps {
  customProp?: string;
}

const StyledSelect = styled(Select)(({ theme }) => ({
  backgroundColor: '#FFFFFF',
  height: '48px',
  color: '#3E3938',
  '& .MuiOutlinedInput-notchedOutline': {
    // borderColor: '#837A78',
    // border: '1px solid #837A78',
    borderRadius: '12px',
    color: '#3E3938',
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderWidth: '1px',
    borderColor: theme.palette.secondary.main,
  },
  "& .MuiPopover-paper": {
    borderRadius: "12px"
  }
}));

const KCSelect: React.FC<KCSelectProps> = (props) => {
  return <StyledSelect {...props} MenuProps={{
    sx: {
      '& .MuiMenuItem-root': {
        color: '#3E3938',
      }
    },
    PaperProps: {
      sx: {
        borderRadius: '12px'
      }
    }
  }} />;
};

export default KCSelect;
