import { Grid, Skeleton, Stack, Box } from "@mui/material";


const EstablishmentSearchCardSkeleton = () => {
    return (
                <Grid container sx={{mb: '20px'}}>
                    <Grid item xs={12} md={3}>
                        <Skeleton variant="rounded" width={238} height={246} />
                    </Grid>
                    <Grid item xs={12} md={9}>
                        <Box sx={{ padding: '10px'}}>
                            <Stack direction={'row'} spacing={2} alignItems="center">
                                <Skeleton variant="rectangular" width={130} height={30}/>
                                <Skeleton variant="rectangular" width={130} height={30}/>
                            </Stack>
                            <Skeleton variant="text" sx={{ fontSize: '4rem'}} />
                            <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={300}/>
                            <Stack direction="row" spacing={6} sx={{ marginTop: '32px'}}>
                                <Box>
                                    <Skeleton variant="text" sx={{ fontsize: '2rem'}} width={100} height={20} />
                                    <Skeleton variant="text" sx={{ fontsize: '2rem'}} width={100} height={60} />
                                </Box>
                                <Box>
                                    <Skeleton variant="text" sx={{ fontsize: '2rem'}} width={100} height={20} />
                                    <Skeleton variant="text" sx={{ fontsize: '2rem'}} width={100} height={60} />
                                </Box>
                                <Box>
                                    <Skeleton variant="text" sx={{ fontsize: '2rem'}} width={100} height={20} />
                                    <Skeleton variant="text" sx={{ fontsize: '2rem'}} width={100} height={60} />
                                </Box>
                            </Stack>
                        </Box>
                    </Grid>
                </Grid>
            )
}

export default EstablishmentSearchCardSkeleton;